import { NavLink, useLocation } from "react-router-dom";
import useGetAllCity from "../../hooks/getCityList";
import useGetAllTeams from "../../hooks/getTeamsLists";
import useCreateSignupLeagues from "../../hooks/signUpLeagues";

const SignUpDetail = ({ stateSetters }) => {
  const { teamslist } = useGetAllTeams();


  const {
    message,
    setMessage,
    showMessage,
    setShowMessage,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    setEmail,
    email,
    document,
    setCellPhone,
    cellPhone,
    setBusinessPhone,
    businessPhone,
    setExt,
    ext,
    setHomePhone,
    homePhone,
    setCountry,
    country,
    setIsParentGuardian,
    isParentGuardian,
    setGender,
    gender,
    setGrade,
    grade,
    setParentEmailAddress,
    // secondaryPersonName,
    // primaryPersonName,
    // setSecondaryPersonaName,
    // setPrimaryName,
    parentEmailAddress,
    setSchoolName,
    schoolName,
    setIsMemberOfLeague,
    isMemberOfLeague,
    setShirtSize,
    shirtSize,
    setJerseySize,
    jerseySize,
    setIsMemberOfTeamOrClub,
    isMemberOfTeamOrClub,
    setClubName,
    clubName,
    setSecondaryEmergencyContact,
    secondaryEmergencyContact,
    setPrimaryEmergencyContact,
    primaryEmergencyContact,
    setPrimaryEmergencyContactEmail,
    primaryEmergencyContactEmail,
    setPrimaryCallPhoneNumber,
    primaryCallPhoneNumber,
    setPhoneNumber,
    phoneNumber,
    setSecondaryEmergencyContactEmail,
    secondaryEmergencyContactEmail,
    setSecondaryCallPhoneNumber,
    secondaryCallPhoneNumber,
    setHowDidYouHear,
    howDidYouHear,
    setHealthConditions,
    healthConditions,
    setAgreeRelease,
    agreeRelease,
    setAgreeActive,
    agreeActive,
    setSignatureType,
    signatureType,
    setElectronicSignature,
    electronicSignature, setDocument,
    setFirstNameError,
    firstNameError,
    setLastNameError,
    lastNameError,
    setEmailError,
    emailError,
    setCellPhoneError,
    cellPhoneError,
    setBusinessPhoneError,
    businessPhoneError,
    setExtError,
    extError,
    setHomePhoneError,
    homePhoneError,
    documentError,
    setDocumentError,
    setCountryError,
    countryError,
    setIsParentGuardianError,
    isParentGuardianError,
    setGenderError,
    genderError,
    setGradeError,
    
    gradeError,
    // setEmailAddressError,
    setParentEmailAddressError,
    parentEmailAddressError,
    emailAddressError,
    setSchoolNameError,
    schoolNameError,
    setIsMemberOfLeagueError,
    isMemberOfLeagueError,
    setShirtSizeError,
    shirtSizeError,
    setJerseySizeError,
    jerseySizeError,
    setIsMemberOfTeamOrClubError,
    isMemberOfTeamOrClubError,
    setClubNameError,
    clubNameError,
    setSecondaryEmergencyContactError,
    secondaryEmergencyContactError,
    setPrimaryEmergencyContactError,
    primaryEmergencyContactError,
    setPrimaryEmergencyContactEmailError,
    primaryEmergencyContactEmailError,
    setPrimaryCallPhoneNumberError,
    setPhoneNumberError,
    phoneNumberError,
    primaryCallPhoneNumberError,
    setSecondaryEmergencyContactEmailError,
    secondaryEmergencyContactEmailError,
    setSecondaryCallPhoneNumberError,
    secondaryCallPhoneNumberError,
    setHowDidYouHearError,
    howDidYouHearError,
    setHealthConditionsError,
    healthConditionsError,
    setAgreeReleaseError,
    agreeReleaseError,
    setAgreeActiveError,
    agreeActiveError,
    setSignatureTypeError,
    signatureTypeError,
    setElectronicSignatureError,
    electronicSignatureError,

  } = stateSetters;

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleCellPhoneChange = (e) => {
    setCellPhone(e.target.value);
    setCellPhoneError(""); // Clear the error when the value changes
  };

  const handleBusinessPhoneChange = (e) => {
    setBusinessPhone(e.target.value);
    setBusinessPhoneError("");
  };

  const handleExtChange = (e) => {
    setExt(e.target.value);
    setExtError("");
  };

  const handleHomePhoneChange = (e) => {
    setHomePhone(e.target.value);
    setHomePhoneError("");
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setCountryError("");

  };


  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleIsParentGuardianChange = (e) => {
    setIsParentGuardian(e.target.checked);
    setIsParentGuardianError("");

  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    setGenderError("");

  };
  const handleGradeChange = (e) => {
    setGrade(e.target.value);
    setGradeError("");

  };
  const handleParentEmailAddressChange = (e) => {
    setParentEmailAddress(e.target.value);
    setParentEmailAddressError("");

  };
  const handleShirtSizeChange = (e) => {
    setShirtSize(e.target.value);
    setShirtSizeError("");

  };
  const handleJerseySizeChange = (e) => {
    setJerseySize(e.target.value);
    setJerseySizeError("");

  };
  const handleIsMemberOfLeagueChange = (e) => {
    setIsMemberOfLeague(e.target.checked);
    setIsMemberOfLeagueError("");

  };
  const handleSchoolNameChange = (e) => {
    setSchoolName(e.target.value);
    setSchoolNameError("");

  };
  const handleIsMemberOfTeamOrClubChange = (e) => {
    setIsMemberOfTeamOrClub(e.target.checked);
    setIsMemberOfTeamOrClubError("");
  };

  const handleClubNameChange = (e) => {
    setClubName(e.target.value);
    setClubNameError("");

  };

  
  // const handlerPrimaryPerson = (e) => {
  //   setPrimaryName(e.target.value);

  // };

  // const handleSecondarPerson = (e) => {
  //   setSecondaryPersonaName(e.target.value);

  // };


  const handlePrimaryEmergencyContactChange = (e) => {
    setPrimaryEmergencyContact(e.target.value);
    setPrimaryEmergencyContactError("");

  };
  const handleSecondaryEmergencyContactChange = (e) => {
    setSecondaryEmergencyContact(e.target.value);
    setSecondaryEmergencyContactError("");

  };
  const handlePrimaryEmergencyContactEmailChange = (e) => {
    setPrimaryEmergencyContactEmail(e.target.value);
    setPrimaryEmergencyContactEmailError("");

  };
  const handleSecondaryEmergencyContactEmailChange = (e) => {
    setSecondaryEmergencyContactEmail(e.target.value);
    setSecondaryEmergencyContactEmailError("");

  };
  const handlePrimaryCallPhoneNumberChange = (e) => {
    setPrimaryCallPhoneNumber(e.target.value);
    setPrimaryCallPhoneNumberError("");

  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setPhoneNumberError("");

  };
  const handleSecondaryCallPhoneNumberChange = (e) => {
    setSecondaryCallPhoneNumber(e.target.value);
    setSecondaryCallPhoneNumberError("");

  };
  const handleHowDidYouHearChange = (e) => {
    setHowDidYouHear(e.target.value);
    setHowDidYouHearError("");

  };

  const handleHealthConditionsChange = (e) => {
    setHealthConditions(e.target.checked);
    setHealthConditionsError("");
    setShowMessage(e.target.value === '1');

  };
  const handleAgreeReleaseChange = (e) => {
    setAgreeRelease(e.target.checked);
    setAgreeReleaseError("");

  };

  const handleAgreeActiveChange = (e) => {
    setAgreeActive(e.target.checked);
    setAgreeActiveError("");

  };

  const handleSignatureTypeChange = (e) => {
    setSignatureType(e.target.value);
    setSignatureTypeError("");

  };
  const handleElectronicSignatureChange = (e) => {
    setElectronicSignature(e.target.value);
    setElectronicSignatureError("");

  };
  const handleDocument = async (e) => {
    const file = e.target.files[0];
    // Handle file upload here if needed
    if (file) {
      setDocument(file);

    } else {
      setDocument(null);
    }
    setDocumentError("");

  };
  const location = useLocation();
  const howDidYouHearOptions = [
    { value: "friend", label: "From a Friend" },
    { value: "socialMedia", label: "Social Media" },
    { value: "website", label: "Website" },
    { value: "event", label: "Event or Workshop" },
    { value: "other", label: "Other" },
  ];
  const shirtSizeOptions = [
    { value: "small", label: "Small" },
    { value: "medium", label: "Medium" },
    { value: "large", label: "Large" },
    { value: "xlarge", label: "X-Large" },
    { value: "xxlarge", label: "XX-Large" },
  ];

  // Access the current page URL
  const currentUrl = location.pathname; // This will give you the path portion of the URL
  // console.log('Current URL:', currentUrl);
  return (
    <div>
      <div className="mt-4">
        <div> Phone Number</div>
        <input
            type="text"
            value={phoneNumber}
            className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
            placeholder=""
            onChange={handlePhoneNumberChange}
          />
          <div className="text-red-500 pb-2">{phoneNumberError}</div>
      </div>
      {currentUrl === "/signupleague" ? (
        <>
          <div className="mt-4 mb-2">
            <h5 className="mb-2">Upload Your Document For Date Of Birth Verification</h5>
            <label
              htmlFor="documentInput"
              className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
            >
              Upload Document
            </label>
            <input
              type="file"
              id="documentInput"
              className="hidden"

              onChange={handleDocument}
            />
            {document && (
              <p className="mt-2">Selected File: {document.name}</p>
            )}
          </div>
          <span className="text-red-500 pb-2">{documentError}</span>
        </>) : ''}
      <div className="mt-4 flex  w-full">
        <div className="flex  mb-4">
          <input
            id="isParentGuardian"
            type="checkbox"
            value={isParentGuardian}
            className="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            onChange={handleIsParentGuardianChange}
          />
          <label
            htmlFor="isParentGuardian"
            className="ml-2 block  text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            You are this person's parent or legal guardian You must be the
            parent or legal guardian to register someone under 18 years old. as
            of 2021-2022 school year
          </label>
        </div>
      </div>
      <span className="text-red-500 pb-2">{isParentGuardianError}</span>
      {
           currentUrl == "/signuptrainingprogram" &&
            <>
              <div className="mt-4">
              <h5>Email address</h5>
              <input
                type="email"
                className="border-[#e7e7e7] border-2 rounded-md p-2 w-full "
                value={email}
                onChange={handleEmailChange}
              />
              <span className="text-red-500 pb-2">{emailError}</span>

              </div>
            </>
          }
      {(currentUrl == "/signupleague" || currentUrl == "/signupleague5x5" || currentUrl == "/signupcamp" || currentUrl == "/signupclinic"  || currentUrl == "/signuptournament") ? (
        <>
          <div className="mt-4">
            <div 
            className="text-red-400 uppercase">
              please confirm your child's grade next fall
            </div>
            <h5 className="mt-2">Grade</h5>
            <select
              name="grade"
              id="grade"
              value={grade}
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              onChange={handleGradeChange}
            >
              <option value="">Select Grade</option>
              <option value="5th">5th</option>
              <option value="7th">7th</option>
              <option value="10th">10th</option>
              <option value="12th">12th</option>
            </select>
            <span className="text-red-500 pb-2">{gradeError}</span>

          </div>
          <div className="mt-4">
            <h5>Email address</h5>
            <input
              type="email"
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={email}
              onChange={handleEmailChange}
            />
            <span className="text-red-500 pb-2">{emailError}</span>

          </div>

          <div className="flex-col items-center mt-2 mb-4">
            <p>
              {(currentUrl === '/signupcamp') ? ' First time playing in the Breaking Boundries Activities ?' : "Is 2021 your child's first year playing in the Hoop it Up League ?"}
              
            </p>
            <input
              id="isFirstYearYes"
              type="radio"
              value="1"
              name="isFirstYear"
              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleIsMemberOfLeagueChange}
            />
            <label
              htmlFor="isFirstYearYes"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Yes
            </label>


          </div>
          <div className="flex items-center">
            <input
              id="isFirstYearNo"
              type="radio"
              value="0"
              name="isFirstYear"
              onChange={handleIsMemberOfLeagueChange}
              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="isFirstYearNo"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              No
            </label>
          </div>
          <span className="text-red-500 pb-2">{isMemberOfLeagueError}</span>
          <div className="mt-8">
            <h5>Leagues Jersey Size</h5>
            <select
              name="jersey_size"
              id="jersey_size"
              value={jerseySize}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleJerseySizeChange}
            >
              <option value="">Choose Size</option>
              <option value="ys">YS</option>
              <option value="ym">YM</option>
              <option value="yl">YL</option>
              <option value="sm">S</option>
              <option value="md">M</option>
              <option value="lg">L</option>
              <option value="xl">XL</option>
              <option value="xxl">XXL</option>
              <option value="xxxl">XXXL</option>
            </select>
            <p className="text-xs">
              Each player will receive their camp jersey on first day play
            </p>
            <span className="text-red-500 pb-2">{jerseySizeError}</span>

          </div>
          <div className="mt-4">
            <h5>School Name</h5>
            <input
              name="schoolname"
              type="text"
              value={schoolName}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleSchoolNameChange}
            />
            <p className="text-xs">
              Please provide full name of your child's school.
            </p>
            <span className="text-red-500 pb-2">{schoolNameError}</span>

          </div>
        </>
      ) : 
        currentUrl != "/signuptrainingprogram" && (
          <>
          <div className="mt-4">
            <h5>Gender</h5>
            <select
              name="gender"
              id="gender"
              value={gender}
              required
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-60 "
              onChange={handleGenderChange}
            >
              <option value="">Choose Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <span className="text-red-500 pb-2">{genderError}</span>
          <div className="mt-4">
            <h5> Shirt Size</h5>
            <select
              name="shirt_size"
              id="shirt_size"
              value={shirtSize}
              required
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleShirtSizeChange}
            >
              <option value="" >
                Select a size
              </option>
              {shirtSizeOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

          </div>
          <span className="text-red-500 pb-2">{shirtSizeError}</span>
        </>
        )
      }
  {currentUrl != "/signuptrainingprogram" && (<>
      <div className="flex-col items-center mt-2 mb-4">
        <p>
          Is the participant currently member of a team or club other than Bay
          Area 3x3 League ?
        </p>
        <input
          id="isMemberOfTeamYes"
          type="radio"
          value="1"
          name="isMemberOfTeam"
          className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={handleIsMemberOfTeamOrClubChange}
        />
        <label
          htmlFor="isMemberOfTeamYes"
          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Yes
        </label>
      </div>
      <div className="flex items-center">
      <input
        id="isMemberOfTeamNo"
        type="radio"
        value="0"
        name="isMemberOfTeam"
        onChange={handleIsMemberOfTeamOrClubChange}
        className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label
        htmlFor="isMemberOfTeamNo"
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        No
      </label>

    </div>
    <span className="text-red-500 pb-2">{isMemberOfTeamOrClubError}</span></>
  )}
      
      <div className="mt-4">
        <h5>Name of Club</h5>
        <input
        
          type="text"
          value={clubName}
          className="border-[#e7e7e7] border-2 rounded-md p-2 w-full "
          onChange={handleClubNameChange}
        />
        <span className="text-red-500 pb-2">{clubNameError}</span>

      </div>
      {  currentUrl != "/signuptrainingprogram" && (<><div className="mt-4">
        <h5>How did you hear about {(currentUrl === '/signupcamp') ? 'Breaking Boundries Athletics' : "The Bay Area Hoop It Up League"}?</h5>
        <select
         
          name="about_bay"
          id="about_bay"
          value={howDidYouHear}
          className="border-[#e7e7e7] border-2 rounded-md p-2  md:w-96 w-60"
          onChange={handleHowDidYouHearChange}
        >
          <option value="">
            Select an option
          </option>
          {howDidYouHearOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="text-red-500 pb-2">{howDidYouHearError}</div>

        <div className="text-[#D70D15] mt-2 text-sm">
          Bay Area 3x3 Hoop It Up Refund is offered by Bay Area 3x3 Hoop It
          Up Network during the registration process if you would like to
          protect your camp registration. This refundable registration
          product is optional and may be purchased ONLY during the
          registration process at checkout. It is not refundable or
          transferable and is not available for purchase after registration
          is complete.
        </div>
      </div>
      <div className="flex lg:w-[62rem] w-full">
        {/* <span className="font-bold text-2xl">•</span> */}
        {/* <div className="flex flex-col border-2 border rounded-md lg:px-4 px-2 py-2"> */}
        <div className="flex flex-col">

          <div className="text-xl font-bold">
            Emergency and Security Information
          </div>
          <div className="text-sm">
            Other than you as the parent/guardian, please provide a secondary
            emergency contact. This person will also have permission to pick-up
            your child. Additional contacts may be submitted before camp starts
            through an online supplemental form.
          </div>
          <div className="mt-4 text-xl font-bold">
            Primary Emergency Contact
          </div>
          <div className="mt-4">
            <h5>
              who is the secondary emergency contact person other than you?
            </h5>
            <select
              
              name="emergency_contact"
              id="emergency_contact"
              value={primaryEmergencyContact}
              className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
              onChange={handlePrimaryEmergencyContactChange}
            >
              <option value="">Choose </option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Brother">Brother</option>
              <option value="Spouce">Spouce</option>
            </select>

          </div>
          <span className="text-red-500 pb-2">{primaryEmergencyContactError}</span>

          <div>Call Phone Number</div>
          <input
           
            type="text"
            value={primaryCallPhoneNumber}
            className="border-[#e7e7e7] border-2 rounded-md p-2  md:w-96 w-60 "
            placeholder=""
            onChange={handlePrimaryCallPhoneNumberChange}
          />
          <div className="text-red-500 pb-2">{primaryCallPhoneNumberError}</div>
           <div>Email Address</div>
            <input
             type="email"
              value={primaryEmergencyContactEmail}
              className="border-[#e7e7e7] border-2 rounded-md p-2  md:w-96 w-60 "
              placeholder=""
              onChange={handlePrimaryEmergencyContactEmailChange}
            />
            <div className="text-red-500 pb-2">{primaryEmergencyContactEmailError}</div>
           <div className="mb-4">
          

            <div className=" mt-4 text-xl font-bold">
              Secondary Emergency Contact
            </div>
              <div className="mt-4">
            <h5>
              who is the secondary emergency contact person other than you?
            </h5>
            <select
            
              name="emergency_contact"
              id="emergency_contact"
              value={secondaryEmergencyContact}
              className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
              onChange={handleSecondaryEmergencyContactChange}
            >
              <option value="">Choose </option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Brother">Brother</option>
              <option value="Spouce">Spouce</option>
            </select>

          </div>
          <span className="text-red-500 pb-2">{secondaryEmergencyContactError}</span>

          <div>Call Phone Number</div>
          <input
          
            type="text"
            value={secondaryCallPhoneNumber}
            className="border-[#e7e7e7] border-2 rounded-md p-2  md:w-96 w-60 "
            placeholder=""
            onChange={handleSecondaryCallPhoneNumberChange}
          />
          <div className="text-red-500 pb-2">{secondaryCallPhoneNumberError}</div>
            <div>Email Address</div>
            <input
            type="email"
              value={secondaryEmergencyContactEmail}
              className="border-[#e7e7e7] border-2 rounded-md p-2  md:w-96 w-60 "
              placeholder=""
              onChange={handleSecondaryEmergencyContactEmailChange}
            />
            <div className="text-red-500 pb-2">{secondaryEmergencyContactEmailError}</div>

          </div>
        </div>
      </div>
      </>)}
     

     { currentUrl != "/signuptrainingprogram" && (<> <div className="flex mt-4 lg:w-[62rem] w-full">
        {/* <span className="font-bold text-2xl">•</span> */}
        {/* <div className="fl
        ex flex-col border-2 border rounded-md lg:px-4 px-2 py-2"> */}
        <div className="flex flex-col">

          <div className="mb-4">
            <div className="text-2xl mt-4 font-bold">
              Health & Safety Information
            </div>
            <div className="text-[#D70D15] text-sm mb-2">
              We will require a guardian Health and safety rela sWaiver form
              DATED WITHIN THE LAST YEAR BEFORE YOUR CAMPER'S START DATE.
            </div>
            <div className="text-[#D70D15] text-sm ">
              Bay Area Hoop It Up Leage POLICIES DO NOT ALLOW DISPENSING, BY
              CAMP STAFF, OF ANY MEDICATION WHILE YOUR CAMPER IS AT CAMP. ALL
              CAMPS HAVE CERTIFIED ATHLETIC TRAINERS ON SITE FOR CPR/FIRST
              AID AND SAFETY.
            </div>
          </div>

        <div className="flex-col items-center mb-4">
  <label
    htmlFor="anyHealthCondition"
    className="mb-2 block text-gray-900 text-sm dark:text-gray-300"
  >
    Are there any health conditions including physical, psychiatric,
    or behavioral issues such as Asthma/Respiratory, ADD/ADHD,
    Anxiety/Depression, or Cardiac Conditions which we need to be
    aware? If yes, you will need to provide the details.
  </label>
  <div className="flex items-center">
    <input
      id="anyHealthConditionYes"
      type="radio"
      value="1"
      name="anyHealthCondition"
      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      onChange={handleHealthConditionsChange}
    />
    <label
      htmlFor="anyHealthConditionYes"
      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
    >
      Yes
    </label>
  </div>
  <div className="flex items-center mt-2">
    <input
      id="anyHealthConditionNo"
      type="radio"
      value="0"
      name="anyHealthCondition"
      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      onChange={handleHealthConditionsChange}
    />
    <label
      htmlFor="anyHealthConditionNo"
      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
    >
      No
    </label>
  </div>
</div>
          <span className="text-red-500 pb-2">{healthConditionsError}</span>
          {showMessage && (
            <div className="mt-2">
              <textarea
                placeholder="Explain"
                value={message}
                onChange={handleMessageChange}
                className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600"
                rows={4} // Set the number of visible rows

              />
            </div>
          )}
        </div>
      </div> </> )}

      <div className="font-bold text-xl mt-4 mb-2">
        {" "}
        Primary Parent / Guardian
      </div>

      <div className="flex lg:w-[62rem] w-full">
        {/* <span className="font-bold text-2xl">•</span> */}
        {/* <div className="flex flex-col border-2 border rounded-md lg:px-4 px-2 py-2"> */}
        <div className="flex flex-col">
        { currentUrl != "/signuptrainingprogram" && (<> <div>First Name</div>
          <input
         
            type="text"
            value={firstName}
            className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
            onChange={handleFirstNameChange}
          />
          <span className="text-red-500 pb-2">{firstNameError}</span>
          <div>Last Name</div>
          <input
          
            type="text"
            value={lastName}
            className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
            onChange={handleLastNameChange}
          />
          <span className="text-red-500 pb-2">{lastNameError}</span>
          <div>Parent Email address</div>
          <input
            type="email"
            value={parentEmailAddress}
            className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
            onChange={handleParentEmailAddressChange}
          />
          <span className="text-red-500 pb-2">{parentEmailAddressError}</span>

          <div>Cell phone number</div>
          <input
           
            type="text"
            value={cellPhone}
            className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
            onChange={handleCellPhoneChange}
          />
          <span className="text-red-500 pb-2">{cellPhoneError}</span></>)}
          <div className="flex lg:flex-row flex-col">
          { currentUrl != "/signuptrainingprogram" && ( <div>
              <div>Business phone number</div>
              <input
              
                type="text"
                value={businessPhone}
                className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
                onChange={handleBusinessPhoneChange}
              />
              <div className="text-red-500 pb-2">{businessPhoneError}</div>
            </div> )}
            <div className="lg:ml-4">
              <div>Ext.</div>
              <input
              
                value={ext}
                type="text"
                className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
                onChange={handleExtChange}
              />
              <div className="text-red-500 pb-2">{extError}</div>
            </div>
          </div>
         
          { currentUrl != "/signuptrainingprogram" && (<>  <div>Home phone number</div> <input
            type="text"
        
            value={homePhone}
            className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
            onChange={handleHomePhoneChange}
          />
          <span className="text-red-500 pb-2">{homePhoneError}</span>
          <div className="mt-4">
            <h5>Country</h5>
            <select
           
              name="country"
              id="country"
              value={country}
              className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
              onChange={handleCountryChange}
            >
              <option value="">
                Choose a country
              </option>
              {countryList.map((value, index) => (
                <option value={value} key={index}>{value}</option>
              ))}
            </select>
          </div>
          <span className="text-red-500 pb-2">{countryError}</span></>)}
        </div>
      </div>
      { currentUrl != "/signuptrainingprogram" && (<> <div className="font-bold text-xl mt-4 mb-2"> Waivers and Agreements</div>
      <div className="flex mt-4 lg:w-[62rem] w-full">
        <div className="flex flex-col  lg:px-4 px-2 py-2">
          <div className="flex mb-4">
            <span className="font-bold text-2xl mr-2 text-gray-200">•</span>
            <span className="text-sm mt-2">
              Please read the following waivers and agreements carefully. They
              include release of liability and waiver of legal rights, and
              deprive you of the ability to sue certain parties. By agreeing
              electronically, you acknowledge that you have both read and
              understood all text presented to you as part of the registration
              process.
            </span>
          </div>
          <div className="flex items-center mb-4">
            <span className="font-bold text-2xl mr-2 text-gray-200">•</span>
            <input
            
              id="agreeRelease"
              type="checkbox"
              value={agreeRelease}
              onChange={handleAgreeReleaseChange}
              className="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="agreeRelease"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-2"
            >
              I agree to the
              <span>RELEASE OF LIABILITY AND CANCELATION POLICY </span>
            </label>
          </div>
          <span className="text-red-500 pb-2">{agreeReleaseError}</span>
          <div className="flex items-center mb-4">
            <span className="font-bold text-2xl mr-2 text-gray-200">•</span>
            <input
              id="agreeActive"
              type="checkbox"
              value={agreeActive}
              onChange={handleAgreeActiveChange}
              className="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="agreeActive"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-2"
            >
              I agree to the <span>Active Agreement and Waiver </span>
            </label>
          </div>
          <span className="text-red-500 pb-2">{agreeActiveError}</span>
        </div>
      </div></>)}

      <div className="font-bold text-xl mt-4 mb-2"> Digital Signature</div>
      <div className="flex mt-4 lg:w-[62rem] w-full">
        <div className="flex flex-col  lg:px-4 px-2 py-2">
          <div className="flex mb-4">
            <span className="font-bold text-2xl mr-2 text-gray-200">•</span>
            <input
           
              id="digitalSignatureWrite"
              type="radio"
              name="digitalSignature"
              value={signatureType}
              onChange={handleSignatureTypeChange}
              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="digitalSignatureWrite"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Write your signature
            </label>
          </div>
          <div className="flex items-center">
            <span className="font-bold text-2xl text-gray-200 mr-2">•</span>
            <input
           
              checked
              id="digitalSignatureType"
              type="radio"
              name="digitalSignature"

              value={signatureType}
              onChange={handleSignatureTypeChange}
              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="digitalSignatureType"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Type your signature
            </label>

          </div>
          <span className="text-red-500 pb-2">{signatureTypeError}</span>
          <div>
            <div className="mt-4 mb-4 text-sm">
              By entering my name below, I assert that I have reviewed and agree
              to all the waivers and agreements I selected above
            </div>
            <div>Electronic signature</div>
            <input
              className="border-[#e7e7e7] border-2 rounded-md p-2 md:w-96 w-60"
              value={electronicSignature}
              onChange={handleElectronicSignatureChange}
            />
          </div>
          <span className="text-red-500 pb-2">{electronicSignatureError}</span>
        </div>
      </div>
    </div>
  );
};
export default SignUpDetail;

const leagues = [
  {
    name: "hoop it up",
    city: "city1",
  },
  {
    name: "sonic",
    city: "city1",
  },
  {
    name: "sizzle",
    city: "city2",
  },
  {
    name: "hoop it up",
    city: "city3",
  },
];
 
export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];