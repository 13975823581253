import React,{useEffect} from 'react';
import { BiCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import useGetAllCity from '../../hooks/getCityList';

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const formattedAgeList = ages.map(age => ({ value: age, label: age }));

const HeaderLg = () => {
  const navigate = useNavigate();
  const {loading,citylist}=useGetAllCity();
  useEffect(() => {
    // Hide the toggleMenu section when the component mounts
    document.getElementById("toggleMenu").style.display = "none";
  }, []);

  // Rest of the code...

  const toggleChange = () => {
    const toggleMenu = document.getElementById("toggleMenu");

    if (toggleMenu.style.display === "none") {
      toggleMenu.style.display = "flex";
    } else {
      toggleMenu.style.display = "none";
    }
  };
  const handleSelectChange = (event) => {
    const selectedCity = event.target.value;
    console.log(selectedCity);
    // Use history.push to navigate to the selected link.
    navigate(`/leaguecity/${selectedCity}`);
  };
  const handleSelectAge = (event) => {
    const selectedAge = event.target.value;
    console.log(selectedAge);
    // Use history.push to navigate to the selected link.
    navigate(`/leagueinfo/`);
  };
  const handleWeeklyScoreSelectChange = (event) => {
    const selectedCity = event.target.value;
    console.log(selectedCity);
    // Use history.push to navigate to the selected link.
    navigate('/leagueplayer');
  };
  return (
    <div className=" bg-[#E7E7E7]">
      <div className="flex bg-white border-b-2 text-[12px] overflow-x-auto">
        <div className="px-2 py-4 text-center border-r-2   border-gary-400">
          <h5 className="lg:block">City Leagues</h5>
          <select
            name="leagues"
            id="leagues"
            className="  border-[#e7e7e7] border-2 rounded-md w-44 text-[12px] my-8"
            onChange={handleSelectChange}
          >
            <option defaultValue>
              Select City
            </option>
            {citylist.map((item, index) => {
              return <option key={index} value={item.name}>{item.name}</option>;
            })}
          </select>
        </div>
        <div className="px-2 py-4 text-center border-r-2   border-gary-400">
          <h5 className="lg:block">Filterable age group divisions</h5>
          <select
            name="leagues"
            id="leagues"
            className="  border-[#e7e7e7] border-2 rounded-md w-44 text-[12px] my-8"
            onChange={handleSelectAge}
            
          >
            <option defaultValue>
              Age Group
            </option>
            {formattedAgeList?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="px-2 py-4 text-center border-r-2 border-gary-400">
          <h5 className="lg:block">Filterable weekly scores</h5>
          <select
            name="leagues"
            id="leagues"
            className="  border-[#e7e7e7] border-2 rounded-md w-44 text-[12px] lg:block my-4"
            onChange={handleWeeklyScoreSelectChange}
         >
            <option defaultValue>
              Select City
            </option>
            {citylist.map((item, index) => {
              return <option key={index}>{item.name}</option>;
            })}
          </select>
          <div className="flex">
            <span className="text-gray-900 text-sm mx-2 font-medium">
              HIDE SCORES
            </span>
            <div className="pl-4">
              <label
                htmlFor="toggle-example"
                className="flex items-center cursor-pointer relative"
              >
                <input
                  type="checkbox"
                  id="toggle-example"
                  className="sr-only"
                  onChange={toggleChange}
                  defaultChecked 
                />
                <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
              </label>
            </div>
          </div>
        </div>
        <div className="flex w-full" id="toggleMenu">
          {match.map((item, index) => (
            <div
              className="py-4 px-1 border-r-2 border-gary-400 w-44"
              key={index}
            >
              <div className="text-sm justify-between">
                <div className="grid grid-cols-2">
                  <div>
                    <span className="block h-10">{item.name}</span>

                    <span className="mt-4 block">
                      <BiCircle className="inline text-xl" />
                      ORL
                    </span>
                    <span className="mt-4 block">
                      <BiCircle className="inline text-xl" />
                      ATL
                    </span>
                  </div>
                  <div>
                    <span className="block h-10">{item.league}</span>
                    <span className="pl-4 mt-4 block">0-0</span>
                    <span className="pl-4 mt-4 block">0-0</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HeaderLg;



const match = [
  {
    name: "READY",
    league: "LEAGUE PASS",
    match: [
      { team: "ORL", score: "0-0" },
      { team: "ATL", score: "0-0" },
    ],
  },
  {
    name: "6:00PM ET",
    league: "NBA TV",
    match: [
      { team: "ORL", score: "0-0" },
      { team: "ATL", score: "0-0" },
    ],
  },
  {
    name: "READY",
    league: "LEAGUE PASS",
    match: [
      { team: "ORL", score: "0-0" },
      { team: "ATL", score: "0-0" },
    ],
  },
  {
    name: "READY",
    league: "LEAGUE PASS",
    match: [
      { team: "ORL", score: "0-0" },
      { team: "ATL", score: "0-0" },
    ],
  },
  {
    name: "READY",
    league: "LEAGUE PASS",
    match: [
      { team: "ORL", score: "0-0" },
      { team: "ATL", score: "0-0" },
    ],
  },
  {
    name: "READY",
    league: "LEAGUE PASS",
    match: [
      { team: "ORL", score: "0-0" },
      { team: "ATL", score: "0-0" },
    ],
  },
];
