import { Table } from "flowbite-react";
import useLeagueInfoData from "../../hooks/leagueInfoDate";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { useEffect } from "react";
import {
  clearLeagueInfoErrors,
  getleagueAgeInfo,
} from "../../redux/actions/leagueInfoAction";
import {
  clearPlayerErrors,
  getAllPlayer,
} from "../../redux/actions/PlayerAction";
import { clearTeamErrors, getAllTeams } from "../../redux/actions/TeamAction";

const LeagueAge = () => {
  // const {data}=useLeagueInfoData();
  const dispatch = useDispatch();
  const { leagueInfo, loading, error } = useSelector(
    (state) => state.leagueInfo
  );
 
  useEffect(() => {
    if (error) {
      dispatch(clearLeagueInfoErrors());
    }
 
    dispatch(getAllPlayer());
    dispatch(getAllTeams());
    // console.log("leagueInfo", data);

      dispatch(getleagueAgeInfo());
  }, []);

  if (loading) {
    return <Loader />;
  }

  // console.log('data',data)
  return (
    <div className="overflow-x-auto">
      <Table className="text-center">
        <Table.Head>
          <Table.HeadCell className="min-w-[30px] bg-white whitespace-nowrap border px-1 py-1 ">
            Age Group
          </Table.HeadCell>
          {leagueInfo?.map((item, index) => (
            <Table.HeadCell
              key={index}
              className="min-w-[30px] bg-white border px-2 py-2"
            >
              {item?.team}
            </Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className="divide-y">
          {Teams.ages.map((age, inage) => (
            <Table.Row
              className={inage % 2 === 0 ? "bg-gray-50" : "bg-white"}
              key={inage}
            >
              <Table.Cell className="whitespace-nowrap px-1 py-1 text-center border font-medium text-gray-900 dark:text-white min-w-[30]">
                {age.label}
              </Table.Cell>
              {leagueInfo?.map((item, index) => (
                <Table.Cell
                  key={index}
                  className="min-w-[30px] text-center border px-2 py-2"
                >
                  {item[age.value]}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default LeagueAge;

const Teams = {
  header: [
    "Team1",
    "Team2",
    "Team3",
    "Team4",
    "Team5",
    "Team6",
    "Team7",
    "Team8",
    "Team9",
    "Team10",
  ],
  ages: [
    { value: "u7", label: "U7" },
    { value: "u8", label: "U8" },
    { value: "u9", label: "U9" },
    { value: "u10", label: "U10" },
    { value: "u11", label: "U11" },
    { value: "u12", label: "U12" },
    { value: "u13", label: "U13" },
    { value: "u14", label: "U14" },
    { value: "u15", label: "U15" },
    { value: "u17", label: "U17" },
    { value: "u18", label: "U18" },
    { value: "u19-u39", label: "U19-U39" },
    { value: "over u40", label: "Over U40" }
  ]
  ,
  data: [
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
    ["5-7", "8-9", "10-12", "13-14", "15-17", "18-40", "40+"],
  ],
};
