import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { toast } from 'react-toastify';
import * as queries from "../graphql/queries"
import * as mutations from '../graphql/mutations';
import z from 'zod';
import { useState } from 'react';


const parseBoolean = (value) => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') {
    const normalized = value.toLowerCase().trim();
    if (normalized === 'true' || normalized === 'yes' || normalized === '1') return true;
    if (normalized === 'false' || normalized === 'no' || normalized === '0') return false;
  }
  if (typeof value === 'number') return value !== 0;
  return false;
};
const strictBooleanParser = (fieldName) => (val) => {
  if (typeof val === 'boolean') return val;
  if (typeof val === 'string') {
    const normalized = val.toLowerCase().trim();
    if (normalized === 'true') return true;
    if (normalized === 'false') return false;
    throw new Error(`${fieldName} must be a boolean or "true"/"false" string. Received: ${val}`);
  }
  throw new Error(`${fieldName} must be a boolean or "true"/"false" string. Received: ${val}`);
};
// Updated schema with consistent boolean handling
const ComptetiveUserSchema = z.object({
  id: z.string(),
  userId: z.string(),
  league: z.string().optional(),
  agegroup: z.string(),
  ext: z.string().optional(),
  participantName: z.string(),
  cityOfRegistration: z.string(),
  teamID: z.string(),
  profile: z.string(),
  teamName: z.string(),
  message: z.string().optional(),
  dateOfBirth: z.string(),
  isAdult: z.preprocess((val) => parseBoolean(val), z.boolean()),
  grade: z.string(),
  email: z.string(),
  jerseySize: z.string(),
  seasonIds: z.array(z.string()),
  schoolName: z.string().optional(),
  isMemberOfOtherTeam: z.preprocess((val) => parseBoolean(val), z.boolean()),
  clubName: z.string().optional(),
  secondaryPersonName: z.string(),
  primaryPersonName: z.string(),
  isFirstYearPlaying: z.preprocess((val) => parseBoolean(val), z.boolean()),
  primaryCallPhoneNumber: z.string(),
  secondaryCallPhoneNumber: z.string(),
  primaryEmergencyContactEmail: z.string(),
  secondaryEmergencyContactEmail: z.string(),
  heardAboutLeague: z.string(),
  healthConditions: z.preprocess((val) => parseBoolean(val), z.boolean()),
  parentFirstName: z.string(),
  parentLastName: z.string(),
  parentEmailAddress: z.string(),
  parentPhoneNumber: z.string(),
  parentBusinessPhoneNumber: z.string(),
  parentHomePhoneNumber: z.string(),
  parentCountry: z.string(),
  signature: z.string(),
  document: z.string(),
  HEIGHT: z.string(),
  WEIGHT: z.string(),
  H_S: z.string(),
  NBA: z.string(),
  COLLEGE: z.string(),
  phoneNumber: z.string(),
});
const transformApiData = (data) => {
  const booleanFields = [
    'isVerified',
    'isAccepted',
    'isAdult',
    'isMemberOfOtherTeam',
    'isFirstYearPlaying',
    'healthConditions'
  ];

  const transformed = { ...data };

  for (const field of booleanFields) {
    if (field in transformed) {
      try {
        if (typeof transformed[field] === 'string') {
          transformed[field] = strictBooleanParser(field)(transformed[field]);
        }
      } catch (error) {
        throw new Error(`Invalid boolean value for ${field}: ${transformed[field]}`);
      }
    }
  }

  return transformed;
};
const useHandleComptetive5x5Players = () => {
  const [loading, setLoading] = useState(false);

  const signUpComptetive5x5Player = async (playerData) => {
    setLoading(true)
    try {
      const transformedData = transformApiData(playerData);
      const parsedData = ComptetiveUserSchema.parse(transformedData);
      const apiName = 'comptetive5x5PlayerApi';
      const path = '/comptetive';
      console.log("transformedData", parsedData)

      const myInit = {
        body: {
          ...parsedData,
          success_url: `https://www.sfbayareabasketball.com/payment-success?isComptetive5x5=true&&id=${parsedData.id}`,

          cancel_url: "https://www.sfbayareabasketball.com/payment-failure?isComptetive5x5=true",
        }, // Use the validated data
        headers: {
          'Content-Type': 'application/json'
        }
      };
      console.log("MUINIT", myInit)
      // Use API.post (lowercase 'p') instead of API.Post
      const response = await API.post(apiName, path, myInit);

      if (response.success) {
      } else {
        throw new Error(response.message || 'Failed to invite player');
      }

      window.location.href = response?.url;
      return response.data;
    } catch (error) {
      // toast.error("Failed to create team.");
      throw new Error(error.data.response.message || error.message || error || 'Failed to invite player');
    } finally {
      setLoading(false)
    }
  };

  const verifyPaymentOfComptetive5x5Player = async (id) => {
    setLoading(true)
    try {

      const apiName = 'comptetive5x5PlayerApi';
      const path = '/verify-payment?id=' + id;

      const myInit = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      console.log("MUINIT", myInit)
      const response = await API.get(apiName, path, myInit);

      if (response.success) {
      } else {
        throw new Error(response.message || 'Failed to invite player');
      }

      return response.data;
    } catch (error) {
      // toast.error("Failed to create team.");
      throw new Error(error.data.response.message || error.message || error || 'Failed to invite player');
    } finally {
      setLoading(false)
    }
  };


  const verifyTokenAndGetUserDetails = async (token) => {
    setLoading(true);

    try {
      console.log("token", token)

      // Validate the data using zod schema

      const apiName = 'inviteComptetive5x5PlayerApi' ;
      const path = '/verify-token?token=' + token;
      const myInit = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      // Use API.post (lowercase 'p') instead of API.Post
      const response = await API.get(apiName, path, myInit);

      if (response.success) {
      } else {
        throw new Error(response.message || 'Failed to invite player');
      }

      return response.data;

    } catch (error) {
      console.error('Error verifying token:', error);
      toast.error(error.response.data.message || "Failed to  verify token.");
      throw new Error(error.data.response.message || error.message || error || 'Failed to invite player');
    } finally {
      setLoading(false);
    }
  };

  const updatePlayers = async (teamId, teamData) => {
    setLoading(true)

    const parsedData = ComptetiveUserSchema.parse(teamData);
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.updateComptetiveUser5X5, { input: { id: teamId, ...parsedData } }),
        { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
      );
      toast.success("Team updated successfully!");
      return response;
    } catch (error) {
      toast.error("Failed to update team.");
      throw error;
    } finally {
      setLoading(false)
    }
  };
  const deletePlayers = async (teamId) => {
    setLoading(true)

    try {
      const response = await API.graphql(
        graphqlOperation(mutations.deleteComptetiveUser5X5, { input: { id: teamId } }),
        { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
      );
      toast.success("Team deleted successfully!");
      return response;
    } catch (error) {
      toast.error("Failed to delete team.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const getPlayerById = async (teamId) => {
    setLoading(true)

    try {
      const response = await API.graphql(
        graphqlOperation(queries.getComptetiveUser5X5, { id: teamId }),
        { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
      );
      return response;
    } catch (error) {
      toast.error("Failed to fetch team.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const getUserMailAndDate = (users) => {
    return users.map((user) => {
      let email;
      let birthdate;
      let userId;

      // Filter out the attributes with 'email_verified' being 'true'
      const verifiedMails = user.Attributes?.filter((attribute) => {
        return attribute.Name === 'email_verified' && attribute.Value === 'true';
      });

      if (verifiedMails.length > 0) {
        user.Attributes.forEach((attribute) => {
          if (attribute.Name === 'email') {
            email = attribute.Value;
          }
          if (attribute.Name === 'birthdate') {
            birthdate = attribute.Value;
          }
          userId = user.Username;
        });
      }

      return { email, birthdate, userId };
    });
  };


  const findUserByMail = async () => {
    setLoading(true)

    try {
      const apiName = 'adminApiForSfbayarea';
      const path = '/admin/listUsers';
      const myInit = {
        queryStringParameters: {
          users: "Users",
          limit: 60,
        },
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const response = await API.get(apiName, path, myInit);


      const data = getUserMailAndDate(response.Users);
      console.log("data", data)
      return data
    } catch (error) {
      toast.error("Failed to fetch team.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const getAllPlayers = async () => {
    setLoading(true)

    try {
      const response = await API.graphql(
        graphqlOperation(queries.listComptetiveUser5X5s),
        { authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS }
      );
      return response;
    } catch (error) {
      toast.error("Failed to fetch teams.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  return {
    signUpComptetive5x5Player,
    updatePlayers,
    deletePlayers,
    getPlayerById,
    getAllPlayers,
    findUserByMail,
    loading,
    verifyTokenAndGetUserDetails,
    verifyPaymentOfComptetive5x5Player
  };
};

export { ComptetiveUserSchema, useHandleComptetive5x5Players };
