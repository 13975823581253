/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      title
      date
      brief
      img_url
      video_url
      youtube_url
      view_count
      content_text
      content_img_url
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        date
        brief
        img_url
        video_url
        youtube_url
        view_count
        content_text
        content_img_url
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories($id: ID!) {
    getCategories(id: $id) {
      id
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        selling_price
        original_price
        rating
        img_url
        tags
        stock_qty
        category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      userid
      cartsItems
      leagueItems
      totalItems
      totalAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        cartsItems
        leagueItems
        totalItems
        totalAmount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCartItem = /* GraphQL */ `
  query GetCartItem($id: ID!) {
    getCartItem(id: $id) {
      id
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems(
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        itemId
        title
        selling_price
        original_price
        rating
        img_url
        tags
        stock_qty
        category
        cartId
        quantity
        finalPrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeagueCart = /* GraphQL */ `
  query GetLeagueCart($id: ID!) {
    getLeagueCart(id: $id) {
      id
      userid
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      cartId
      quantity
      finalPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeagueCarts = /* GraphQL */ `
  query ListLeagueCarts(
    $id: ID
    $filter: ModelLeagueCartFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeagueCarts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userid
        leagueId
        title
        date
        location
        img_url
        selling_price
        teamsId
        teamsName
        cartId
        quantity
        finalPrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeague = /* GraphQL */ `
  query GetLeague($id: ID!) {
    getLeague(id: $id) {
      id
      title
      date
      location
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeagues = /* GraphQL */ `
  query ListLeagues(
    $id: ID
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeagues(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        date
        location
        img_url
        selling_price
        teamsId {
          firstTeamId
          secondTeamId
          __typename
        }
        teamsName {
          firstTeam
          secondTeam
          __typename
        }
        eventStatus
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeague5x5 = /* GraphQL */ `
  query GetLeague5x5($id: ID!) {
    getLeague5x5(id: $id) {
      id
      title
      date
      location
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeague5x5s = /* GraphQL */ `
  query ListLeague5x5s(
    $id: ID
    $filter: ModelLeague5x5FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLeague5x5s(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        date
        location
        img_url
        selling_price
        teamsId {
          firstTeamId
          secondTeamId
          __typename
        }
        teamsName {
          firstTeam
          secondTeam
          __typename
        }
        eventStatus
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      placed_date
      shipped_date
      delivered_date
      cancelled_date
      userid
      itemId
      title
      selling_price
      original_price
      rating
      img_url
      tags
      stock_qty
      category
      cartId
      quantity
      cartItemId
      finalPrice
      code
      couponValue
      amount
      sessionId
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        placed_date
        shipped_date
        delivered_date
        cancelled_date
        userid
        itemId
        title
        selling_price
        original_price
        rating
        img_url
        tags
        stock_qty
        category
        cartId
        quantity
        cartItemId
        finalPrice
        code
        couponValue
        amount
        sessionId
        transactionStatus
        orderStatus
        address
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookedEvent = /* GraphQL */ `
  query GetBookedEvent($id: ID!) {
    getBookedEvent(id: $id) {
      id
      userid
      placed_date
      eventDate
      eventEnded
      leagueId
      title
      date
      location
      img_url
      selling_price
      teamsId
      teamsName
      leagueCartId
      quantity
      finalPrice
      sessionId
      code
      couponValue
      transactionStatus
      orderStatus
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBookedEvents = /* GraphQL */ `
  query ListBookedEvents(
    $filter: ModelBookedEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookedEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        placed_date
        eventDate
        eventEnded
        leagueId
        title
        date
        location
        img_url
        selling_price
        teamsId
        teamsName
        leagueCartId
        quantity
        finalPrice
        sessionId
        code
        couponValue
        transactionStatus
        orderStatus
        address
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      imageURL
      leagueId
      scoreID
      agegroup
      location
      city
      players {
        items {
          id
          userId
          isVerified
          league
          agegroup
          ext
          participantName
          cityOfRegistration
          scoreID
          teamID
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          jerseySize
          jerseyNo
          sessionId
          seasonIds
          schoolName
          transactionStatus
          memberExpire
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          isFirstYearPlaying
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          document
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageURL
        leagueId
        scoreID
        agegroup
        location
        city
        players {
          items {
            id
            userId
            isVerified
            league
            agegroup
            ext
            participantName
            cityOfRegistration
            scoreID
            teamID
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            jerseySize
            jerseyNo
            sessionId
            seasonIds
            schoolName
            transactionStatus
            memberExpire
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            isFirstYearPlaying
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            document
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam5x5 = /* GraphQL */ `
  query GetTeam5x5($id: ID!) {
    getTeam5x5(id: $id) {
      id
      name
      imageURL
      leagueId
      scoreID
      agegroup
      location
      city
      players {
        items {
          id
          userId
          isVerified
          league
          agegroup
          ext
          participantName
          cityOfRegistration
          scoreID
          teamID
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          jerseySize
          jerseyNo
          sessionId
          seasonIds
          schoolName
          transactionStatus
          memberExpire
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          isFirstYearPlaying
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          document
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeam5x5s = /* GraphQL */ `
  query ListTeam5x5s(
    $filter: ModelTeam5x5FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeam5x5s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageURL
        leagueId
        scoreID
        agegroup
        location
        city
        players {
          items {
            id
            userId
            isVerified
            league
            agegroup
            ext
            participantName
            cityOfRegistration
            scoreID
            teamID
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            jerseySize
            jerseyNo
            sessionId
            seasonIds
            schoolName
            transactionStatus
            memberExpire
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            isFirstYearPlaying
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            document
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $id: ID
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlayers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorkWithUs = /* GraphQL */ `
  query GetWorkWithUs($id: ID!) {
    getWorkWithUs(id: $id) {
      id
      userId
      participantName
      email
      applyingFor
      preferredCityOfEvent
      resume
      dateOfBirth
      isAdult
      ext
      shirtSize
      isMemberOfOtherClub
      clubName
      gender
      secondaryPersonName
      transactionStatus
      sessionId
      message
      memberExpire
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWorkWithuses = /* GraphQL */ `
  query ListWorkWithuses(
    $filter: ModelWorkWithUsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkWithuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        participantName
        email
        applyingFor
        preferredCityOfEvent
        resume
        dateOfBirth
        isAdult
        ext
        shirtSize
        isMemberOfOtherClub
        clubName
        gender
        secondaryPersonName
        transactionStatus
        sessionId
        message
        memberExpire
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVolunteer = /* GraphQL */ `
  query GetVolunteer($id: ID!) {
    getVolunteer(id: $id) {
      participantName
      event
      email
      userId
      preferredCity
      volunteerSpecialty
      message
      msg
      familyMemberName
      ext
      transactionStatus
      sessionId
      memberExpire
      dateOfBirth
      isAdult
      gender
      shirtSize
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      agegroup
      phoneNumber
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVolunteers = /* GraphQL */ `
  query ListVolunteers(
    $filter: ModelVolunteerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVolunteers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        participantName
        event
        email
        userId
        preferredCity
        volunteerSpecialty
        message
        msg
        familyMemberName
        ext
        transactionStatus
        sessionId
        memberExpire
        dateOfBirth
        isAdult
        gender
        shirtSize
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        agegroup
        phoneNumber
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinic = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      userId
      sessionId
      type
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      courseID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      schoolName
      paymentStatus
      isFirstYearPlaying
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      agegroup
      phoneNumber
      appointmentId
      appointmentScheduleId
      used
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinics = /* GraphQL */ `
  query ListClinics(
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        sessionId
        type
        league
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        courseID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        schoolName
        paymentStatus
        isFirstYearPlaying
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        agegroup
        phoneNumber
        appointmentId
        appointmentScheduleId
        used
        appointmentSchedule {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinicCourse = /* GraphQL */ `
  query GetClinicCourse($id: ID!) {
    getClinicCourse(id: $id) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinicCourses = /* GraphQL */ `
  query ListClinicCourses(
    $filter: ModelClinicCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        price
        description
        quantity
        calendlyLink
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCamp = /* GraphQL */ `
  query GetCamp($id: ID!) {
    getCamp(id: $id) {
      id
      userId
      sessionId
      courseID
      league
      ext
      participantName
      cityOfRegistration
      scoreID
      campID
      profile
      campName
      message
      dateOfBirth
      isAdult
      grade
      isFirstYearPlaying
      transactionStatus
      memberExpire
      email
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      experience
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCamps = /* GraphQL */ `
  query ListCamps(
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        sessionId
        courseID
        league
        ext
        participantName
        cityOfRegistration
        scoreID
        campID
        profile
        campName
        message
        dateOfBirth
        isAdult
        grade
        isFirstYearPlaying
        transactionStatus
        memberExpire
        email
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        experience
        phoneNumber
        agegroup
        used
        appointmentId
        appointmentScheduleId
        appointmentSchedule {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCampEvent = /* GraphQL */ `
  query GetCampEvent($id: ID!) {
    getCampEvent(id: $id) {
      id
      cityOfRegistration
      name
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCampEvents = /* GraphQL */ `
  query ListCampEvents(
    $filter: ModelCampEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cityOfRegistration
        name
        agegroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCampCard = /* GraphQL */ `
  query GetCampCard($id: ID!) {
    getCampCard(id: $id) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCampCards = /* GraphQL */ `
  query ListCampCards(
    $filter: ModelCampCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        price
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCampCourse = /* GraphQL */ `
  query GetCampCourse($id: ID!) {
    getCampCourse(id: $id) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCampCourses = /* GraphQL */ `
  query ListCampCourses(
    $filter: ModelCampCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        price
        description
        quantity
        calendlyLink
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSignUpEvent = /* GraphQL */ `
  query GetSignUpEvent($id: ID!) {
    getSignUpEvent(id: $id) {
      id
      userId
      league
      cityName
      description
      transactionStatus
      memberExpire
      sessionId
      email
      dateOfBirth
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSignUpEvents = /* GraphQL */ `
  query ListSignUpEvents(
    $filter: ModelSignUpEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignUpEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        league
        cityName
        description
        transactionStatus
        memberExpire
        sessionId
        email
        dateOfBirth
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTournament = /* GraphQL */ `
  query GetTournament($id: ID!) {
    getTournament(id: $id) {
      id
      userId
      sessionId
      isVerified
      ext
      participantName
      tournamentId
      team
      teamID
      matches {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      isFirstYearPlaying
      jerseyNo
      transactionStatus
      memberExpire
      jerseySize
      schoolName
      paymentStatus
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      agegroup
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTournaments = /* GraphQL */ `
  query ListTournaments(
    $id: ID
    $filter: ModelTournamentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTournaments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        sessionId
        isVerified
        ext
        participantName
        tournamentId
        team
        teamID
        matches {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        isFirstYearPlaying
        jerseyNo
        transactionStatus
        memberExpire
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrainingProgram = /* GraphQL */ `
  query GetTrainingProgram($id: ID!) {
    getTrainingProgram(id: $id) {
      id
      userId
      isVerified
      ext
      participantName
      type
      courseID
      teamId
      teamName
      cityOfRegistration
      profile
      message
      dateOfBirth
      transactionStatus
      sessionId
      memberExpire
      isAdult
      email
      paymentStatus
      clubName
      signature
      phoneNumber
      agegroup
      used
      appointmentId
      appointmentScheduleId
      appointmentSchedule {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTrainingPrograms = /* GraphQL */ `
  query ListTrainingPrograms(
    $filter: ModelTrainingProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingPrograms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isVerified
        ext
        participantName
        type
        courseID
        teamId
        teamName
        cityOfRegistration
        profile
        message
        dateOfBirth
        transactionStatus
        sessionId
        memberExpire
        isAdult
        email
        paymentStatus
        clubName
        signature
        phoneNumber
        agegroup
        used
        appointmentId
        appointmentScheduleId
        appointmentSchedule {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      organization
      reason
      userId
      number
      email
      message
      sessionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organization
        reason
        userId
        number
        email
        message
        sessionId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      userid
      addresses {
        city
        country
        pincode
        address
        phonenumber
        secondaryPhonenumber
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userid
        addresses {
          city
          country
          pincode
          address
          phonenumber
          secondaryPhonenumber
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScore = /* GraphQL */ `
  query GetScore($id: ID!) {
    getScore(id: $id) {
      id
      event
      eventId
      eventImage
      matchNumber
      round
      teams {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      firstTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      secondTeam {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      tournament
      winner
      runnerUp
      agegroup
      courtNo
      record
      ppg
      rpe
      apg
      ranking
      city
      date
      time
      playerDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      firstTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      secondTeamDetails {
        teamId
        teamName
        teamLogo
        playerId
        playerName
        playerImage
        GP
        MIN
        PTS
        FGM
        FGA
        FGPERCENT
        THREEPM
        THREEPA
        THREEPPERCENT
        FTM
        FTA
        FTPERCENT
        OREB
        DREB
        REB
        AST
        TOV
        STL
        BLK
        PF
        FP
        DDTWO
        TDTHREE
        PlusMinus
        __typename
      }
      eventStatus
      roundId
      eventsId
      winnerId
      player {
        items {
          id
          tournamentId
          scoreId
          tournament {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          score {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScores = /* GraphQL */ `
  query ListScores(
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscribedUsers = /* GraphQL */ `
  query GetSubscribedUsers($id: ID!) {
    getSubscribedUsers(id: $id) {
      email
      exported
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscribedUsers = /* GraphQL */ `
  query ListSubscribedUsers(
    $filter: ModelSubscribedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        exported
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvertisement = /* GraphQL */ `
  query GetAdvertisement($id: ID!) {
    getAdvertisement(id: $id) {
      id
      lg_img_url
      md_img_url
      sm_img_url
      xs_img_url
      page
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdvertisements = /* GraphQL */ `
  query ListAdvertisements(
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lg_img_url
        md_img_url
        sm_img_url
        xs_img_url
        page
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscountCoupon = /* GraphQL */ `
  query GetDiscountCoupon($id: ID!) {
    getDiscountCoupon(id: $id) {
      id
      code
      couponValue
      discountOnUpto
      validFrom
      validTo
      isUserLimit
      uniqueProduct
      productCategories
      allProductsDiscount
      description
      isActive
      maxUsageLimit
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDiscountCoupons = /* GraphQL */ `
  query ListDiscountCoupons(
    $filter: ModelDiscountCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        couponValue
        discountOnUpto
        validFrom
        validTo
        isUserLimit
        uniqueProduct
        productCategories
        allProductsDiscount
        description
        isActive
        maxUsageLimit
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSeasonCharges = /* GraphQL */ `
  query GetSeasonCharges($id: ID!) {
    getSeasonCharges(id: $id) {
      id
      title
      description
      price
      type
      startingDate
      endingDate
      agegroup
      location
      lastRegistrationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSeasonCharges = /* GraphQL */ `
  query ListSeasonCharges(
    $filter: ModelSeasonChargesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeasonCharges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        price
        type
        startingDate
        endingDate
        agegroup
        location
        lastRegistrationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPages = /* GraphQL */ `
  query GetPages($id: ID!) {
    getPages(id: $id) {
      id
      title
      ads
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        ads
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPricePages = /* GraphQL */ `
  query GetPricePages($id: ID!) {
    getPricePages(id: $id) {
      id
      type
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPricePages = /* GraphQL */ `
  query ListPricePages(
    $filter: ModelPricePagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricePages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinicCard = /* GraphQL */ `
  query GetClinicCard($id: ID!) {
    getClinicCard(id: $id) {
      id
      title
      price
      order
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinicCards = /* GraphQL */ `
  query ListClinicCards(
    $filter: ModelClinicCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        price
        order
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrainingProgramCard = /* GraphQL */ `
  query GetTrainingProgramCard($id: ID!) {
    getTrainingProgramCard(id: $id) {
      id
      title
      price
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTrainingProgramCards = /* GraphQL */ `
  query ListTrainingProgramCards(
    $filter: ModelTrainingProgramCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingProgramCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrainingProgramCourse = /* GraphQL */ `
  query GetTrainingProgramCourse($id: ID!) {
    getTrainingProgramCourse(id: $id) {
      id
      title
      price
      description
      quantity
      calendlyLink
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTrainingProgramCourses = /* GraphQL */ `
  query ListTrainingProgramCourses(
    $filter: ModelTrainingProgramCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingProgramCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        price
        description
        quantity
        calendlyLink
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTournamentTeam = /* GraphQL */ `
  query GetTournamentTeam($id: ID!) {
    getTournamentTeam(id: $id) {
      id
      name
      imageURL
      scoreID
      agegroup
      location
      event
      eventId
      city
      tournamentId
      players {
        items {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTournamentTeams = /* GraphQL */ `
  query ListTournamentTeams(
    $id: ID
    $filter: ModelTournamentTeamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTournamentTeams(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        imageURL
        scoreID
        agegroup
        location
        event
        eventId
        city
        tournamentId
        players {
          items {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTournamentLeague = /* GraphQL */ `
  query GetTournamentLeague($id: ID!) {
    getTournamentLeague(id: $id) {
      id
      title
      date
      agegroup
      img_url
      selling_price
      teamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      teamsName {
        firstTeam
        secondTeam
        __typename
      }
      allTeamsId {
        firstTeamId
        secondTeamId
        __typename
      }
      allTeamsName {
        firstTeam
        secondTeam
        __typename
      }
      eventStatus
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTournamentLeagues = /* GraphQL */ `
  query ListTournamentLeagues(
    $id: ID
    $filter: ModelTournamentLeagueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTournamentLeagues(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        date
        agegroup
        img_url
        selling_price
        teamsId {
          firstTeamId
          secondTeamId
          __typename
        }
        teamsName {
          firstTeam
          secondTeam
          __typename
        }
        allTeamsId {
          firstTeamId
          secondTeamId
          __typename
        }
        allTeamsName {
          firstTeam
          secondTeam
          __typename
        }
        eventStatus
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWinner = /* GraphQL */ `
  query GetWinner($id: ID!) {
    getWinner(id: $id) {
      id
      eventName
      eventId
      tournamentId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      totalRounds
      eventStatus
      rounds {
        items {
          id
          eventName
          eventId
          matches {
            items {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          winner {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          runnerUpList
          runnerUp {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          round
          eventStatus
          winnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWinners = /* GraphQL */ `
  query ListWinners(
    $id: ID
    $filter: ModelWinnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWinners(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventName
        eventId
        tournamentId
        matches {
          items {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        winner {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        runnerUpList
        runnerUp {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        totalRounds
        eventStatus
        rounds {
          items {
            id
            eventName
            eventId
            matches {
              items {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            winner {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            runnerUpList
            runnerUp {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            round
            eventStatus
            winnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRounds = /* GraphQL */ `
  query GetRounds($id: ID!) {
    getRounds(id: $id) {
      id
      eventName
      eventId
      matches {
        items {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      winner {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      runnerUpList
      runnerUp {
        teamId
        teamName
        teamLogo
        score
        __typename
      }
      round
      eventStatus
      winnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRounds = /* GraphQL */ `
  query ListRounds(
    $id: ID
    $filter: ModelRoundsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRounds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventName
        eventId
        matches {
          items {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        winner {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        runnerUpList
        runnerUp {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        round
        eventStatus
        winnerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeet = /* GraphQL */ `
  query GetMeet($id: ID!) {
    getMeet(id: $id) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeets = /* GraphQL */ `
  query ListMeets(
    $filter: ModelMeetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        selectedDate
        from
        exceptionDays
        exceptionDate
        price
        everyDay
        description
        enable
        maxUser
        duration
        userRegistered
        courseId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinicMeet = /* GraphQL */ `
  query GetClinicMeet($id: ID!) {
    getClinicMeet(id: $id) {
      id
      title
      selectedDate
      from
      exceptionDays
      exceptionDate
      price
      everyDay
      description
      enable
      maxUser
      duration
      userRegistered
      courseId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinicMeets = /* GraphQL */ `
  query ListClinicMeets(
    $filter: ModelClinicMeetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicMeets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        selectedDate
        from
        exceptionDays
        exceptionDate
        price
        everyDay
        description
        enable
        maxUser
        duration
        userRegistered
        courseId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinicUserMeet = /* GraphQL */ `
  query GetClinicUserMeet($id: ID!) {
    getClinicUserMeet(id: $id) {
      id
      meetId
      email
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClinicUserMeets = /* GraphQL */ `
  query ListClinicUserMeets(
    $filter: ModelClinicUserMeetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicUserMeets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meetId
        email
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      name
      description
      duration
      maxStudents
      price
      schedule {
        items {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      courseId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $id: ID
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppointments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        duration
        maxStudents
        price
        schedule {
          items {
            id
            tutorId
            appointmentId
            slots {
              startTime
              endTime
              __typename
            }
            maxStudents
            excludeDays
            excludeSlots {
              date
              slots {
                startTime
                endTime
                __typename
              }
              __typename
            }
            daily
            days
            dates
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        courseId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTutor = /* GraphQL */ `
  query GetTutor($id: ID!) {
    getTutor(id: $id) {
      id
      name
      profile
      bio
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTutors = /* GraphQL */ `
  query ListTutors(
    $id: ID
    $filter: ModelTutorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTutors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        profile
        bio
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppointmentSchedule = /* GraphQL */ `
  query GetAppointmentSchedule($id: ID!) {
    getAppointmentSchedule(id: $id) {
      id
      tutorId
      appointmentId
      slots {
        startTime
        endTime
        __typename
      }
      maxStudents
      excludeDays
      excludeSlots {
        date
        slots {
          startTime
          endTime
          __typename
        }
        __typename
      }
      daily
      days
      dates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppointmentSchedules = /* GraphQL */ `
  query ListAppointmentSchedules(
    $id: ID
    $filter: ModelAppointmentScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppointmentSchedules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserMeet = /* GraphQL */ `
  query GetUserMeet($id: ID!) {
    getUserMeet(id: $id) {
      id
      meetId
      email
      userId
      date
      appointmentId
      slot {
        startTime
        endTime
        __typename
      }
      courseID
      appointmentScheduleId
      type
      typeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserMeets = /* GraphQL */ `
  query ListUserMeets(
    $filter: ModelUserMeetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMeets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meetId
        email
        userId
        date
        appointmentId
        slot {
          startTime
          endTime
          __typename
        }
        courseID
        appointmentScheduleId
        type
        typeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivities = /* GraphQL */ `
  query GetActivities($id: ID!) {
    getActivities(id: $id) {
      id
      type
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCarouselImages = /* GraphQL */ `
  query GetCarouselImages($id: ID!) {
    getCarouselImages(id: $id) {
      id
      image
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCarouselImages = /* GraphQL */ `
  query ListCarouselImages(
    $filter: ModelCarouselImagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarouselImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        text
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComptetiveTeam = /* GraphQL */ `
  query GetComptetiveTeam($id: ID!) {
    getComptetiveTeam(id: $id) {
      id
      name
      coachName
      coachId
      agegroup
      imageURL
      location
      city
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComptetiveTeams = /* GraphQL */ `
  query ListComptetiveTeams(
    $filter: ModelComptetiveTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComptetiveTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        coachName
        coachId
        agegroup
        imageURL
        location
        city
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComptetiveUser = /* GraphQL */ `
  query GetComptetiveUser($id: ID!) {
    getComptetiveUser(id: $id) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      teamID
      isAccepted
      profile
      teamName
      message
      token
      tokenExpire
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComptetiveUsers = /* GraphQL */ `
  query ListComptetiveUsers(
    $filter: ModelComptetiveUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComptetiveUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        teamID
        isAccepted
        profile
        teamName
        message
        token
        tokenExpire
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTryout = /* GraphQL */ `
  query GetTryout($id: ID!) {
    getTryout(id: $id) {
      id
      userId
      name
      agegroup
      cityOfRegistration
      dateOfBirth
      email
      sessionId
      transactionStatus
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTryouts = /* GraphQL */ `
  query ListTryouts(
    $id: ID
    $filter: ModelTryoutFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTryouts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        name
        agegroup
        cityOfRegistration
        dateOfBirth
        email
        sessionId
        transactionStatus
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComptetiveCoach = /* GraphQL */ `
  query GetComptetiveCoach($id: ID!) {
    getComptetiveCoach(id: $id) {
      id
      name
      email
      phoneNumber
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComptetiveCoaches = /* GraphQL */ `
  query ListComptetiveCoaches(
    $filter: ModelComptetiveCoachFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComptetiveCoaches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phoneNumber
        address
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTryout5X5 = /* GraphQL */ `
  query GetTryout5X5($id: ID!) {
    getTryout5X5(id: $id) {
      id
      userId
      name
      agegroup
      cityOfRegistration
      dateOfBirth
      email
      sessionId
      transactionStatus
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTryout5X5s = /* GraphQL */ `
  query ListTryout5X5s(
    $id: ID
    $filter: ModelTryout5X5FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTryout5X5s(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        name
        agegroup
        cityOfRegistration
        dateOfBirth
        email
        sessionId
        transactionStatus
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComptetiveTeam5X5 = /* GraphQL */ `
  query GetComptetiveTeam5X5($id: ID!) {
    getComptetiveTeam5X5(id: $id) {
      id
      name
      coachName
      coachId
      agegroup
      imageURL
      location
      city
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComptetiveTeam5X5s = /* GraphQL */ `
  query ListComptetiveTeam5X5s(
    $filter: ModelComptetiveTeam5X5FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComptetiveTeam5X5s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        coachName
        coachId
        agegroup
        imageURL
        location
        city
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComptetiveCoach5X5 = /* GraphQL */ `
  query GetComptetiveCoach5X5($id: ID!) {
    getComptetiveCoach5X5(id: $id) {
      id
      name
      email
      phoneNumber
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComptetiveCoach5X5s = /* GraphQL */ `
  query ListComptetiveCoach5X5s(
    $filter: ModelComptetiveCoach5X5FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComptetiveCoach5X5s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phoneNumber
        address
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComptetiveUser5X5 = /* GraphQL */ `
  query GetComptetiveUser5X5($id: ID!) {
    getComptetiveUser5X5(id: $id) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      teamID
      isAccepted
      profile
      teamName
      message
      token
      tokenExpire
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComptetiveUser5X5s = /* GraphQL */ `
  query ListComptetiveUser5X5s(
    $filter: ModelComptetiveUser5X5FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComptetiveUser5X5s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        teamID
        isAccepted
        profile
        teamName
        message
        token
        tokenExpire
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlayer5x5 = /* GraphQL */ `
  query GetPlayer5x5($id: ID!) {
    getPlayer5x5(id: $id) {
      id
      userId
      isVerified
      league
      agegroup
      ext
      participantName
      cityOfRegistration
      scoreID
      teamID
      profile
      teamName
      message
      dateOfBirth
      isAdult
      grade
      email
      jerseySize
      jerseyNo
      sessionId
      seasonIds
      schoolName
      transactionStatus
      memberExpire
      isMemberOfOtherTeam
      clubName
      secondaryPersonName
      primaryPersonName
      isFirstYearPlaying
      primaryCallPhoneNumber
      secondaryCallPhoneNumber
      primaryEmergencyContactEmail
      secondaryEmergencyContactEmail
      heardAboutLeague
      healthConditions
      parentFirstName
      parentLastName
      parentEmailAddress
      parentPhoneNumber
      parentBusinessPhoneNumber
      parentHomePhoneNumber
      parentCountry
      signature
      document
      HEIGHT
      WEIGHT
      H_S
      NBA
      COLLEGE
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlayer5x5s = /* GraphQL */ `
  query ListPlayer5x5s(
    $id: ID
    $filter: ModelPlayer5x5FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlayer5x5s(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlayerScore = /* GraphQL */ `
  query GetPlayerScore($id: ID!) {
    getPlayerScore(id: $id) {
      id
      tournamentId
      scoreId
      tournament {
        id
        userId
        sessionId
        isVerified
        ext
        participantName
        tournamentId
        team
        teamID
        matches {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        isFirstYearPlaying
        jerseyNo
        transactionStatus
        memberExpire
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      score {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlayerScores = /* GraphQL */ `
  query ListPlayerScores(
    $filter: ModelPlayerScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayerScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tournamentId
        scoreId
        tournament {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        score {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const newsByTypeAndDate = /* GraphQL */ `
  query NewsByTypeAndDate(
    $type: postType!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsByTypeAndDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        date
        brief
        img_url
        video_url
        youtube_url
        view_count
        content_text
        content_img_url
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cartItemsByCartId = /* GraphQL */ `
  query CartItemsByCartId(
    $cartId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartItemsByCartId(
      cartId: $cartId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userid
        itemId
        title
        selling_price
        original_price
        rating
        img_url
        tags
        stock_qty
        category
        cartId
        quantity
        finalPrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const leagueCartsByCartId = /* GraphQL */ `
  query LeagueCartsByCartId(
    $cartId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLeagueCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leagueCartsByCartId(
      cartId: $cartId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userid
        leagueId
        title
        date
        location
        img_url
        selling_price
        teamsId
        teamsName
        cartId
        quantity
        finalPrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playersByScoreID = /* GraphQL */ `
  query PlayersByScoreID(
    $scoreID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playersByScoreID(
      scoreID: $scoreID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playersByTeamID = /* GraphQL */ `
  query PlayersByTeamID(
    $teamID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playersByTeamID(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const clinicsByScoreID = /* GraphQL */ `
  query ClinicsByScoreID(
    $scoreID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clinicsByScoreID(
      scoreID: $scoreID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sessionId
        type
        league
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        courseID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        schoolName
        paymentStatus
        isFirstYearPlaying
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        agegroup
        phoneNumber
        appointmentId
        appointmentScheduleId
        used
        appointmentSchedule {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const campsByScoreID = /* GraphQL */ `
  query CampsByScoreID(
    $scoreID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campsByScoreID(
      scoreID: $scoreID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sessionId
        courseID
        league
        ext
        participantName
        cityOfRegistration
        scoreID
        campID
        profile
        campName
        message
        dateOfBirth
        isAdult
        grade
        isFirstYearPlaying
        transactionStatus
        memberExpire
        email
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        experience
        phoneNumber
        agegroup
        used
        appointmentId
        appointmentScheduleId
        appointmentSchedule {
          id
          tutorId
          appointmentId
          slots {
            startTime
            endTime
            __typename
          }
          maxStudents
          excludeDays
          excludeSlots {
            date
            slots {
              startTime
              endTime
              __typename
            }
            __typename
          }
          daily
          days
          dates
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tournamentsByTeam = /* GraphQL */ `
  query TournamentsByTeam(
    $team: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTournamentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tournamentsByTeam(
      team: $team
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        sessionId
        isVerified
        ext
        participantName
        tournamentId
        team
        teamID
        matches {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        isFirstYearPlaying
        jerseyNo
        transactionStatus
        memberExpire
        jerseySize
        schoolName
        paymentStatus
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        agegroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scoresByRoundId = /* GraphQL */ `
  query ScoresByRoundId(
    $roundId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scoresByRoundId(
      roundId: $roundId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scoresByWinnerId = /* GraphQL */ `
  query ScoresByWinnerId(
    $winnerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scoresByWinnerId(
      winnerId: $winnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        eventId
        eventImage
        matchNumber
        round
        teams {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        firstTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        secondTeam {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        tournament
        winner
        runnerUp
        agegroup
        courtNo
        record
        ppg
        rpe
        apg
        ranking
        city
        date
        time
        playerDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        firstTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        secondTeamDetails {
          teamId
          teamName
          teamLogo
          playerId
          playerName
          playerImage
          GP
          MIN
          PTS
          FGM
          FGA
          FGPERCENT
          THREEPM
          THREEPA
          THREEPPERCENT
          FTM
          FTA
          FTPERCENT
          OREB
          DREB
          REB
          AST
          TOV
          STL
          BLK
          PF
          FP
          DDTWO
          TDTHREE
          PlusMinus
          __typename
        }
        eventStatus
        roundId
        eventsId
        winnerId
        player {
          items {
            id
            tournamentId
            scoreId
            tournament {
              id
              userId
              sessionId
              isVerified
              ext
              participantName
              tournamentId
              team
              teamID
              matches {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              profile
              teamName
              message
              dateOfBirth
              isAdult
              grade
              email
              isFirstYearPlaying
              jerseyNo
              transactionStatus
              memberExpire
              jerseySize
              schoolName
              paymentStatus
              isMemberOfOtherTeam
              clubName
              secondaryPersonName
              primaryPersonName
              primaryCallPhoneNumber
              secondaryCallPhoneNumber
              primaryEmergencyContactEmail
              secondaryEmergencyContactEmail
              heardAboutLeague
              healthConditions
              parentFirstName
              parentLastName
              parentEmailAddress
              parentPhoneNumber
              parentBusinessPhoneNumber
              parentHomePhoneNumber
              parentCountry
              signature
              HEIGHT
              WEIGHT
              H_S
              NBA
              COLLEGE
              phoneNumber
              agegroup
              createdAt
              updatedAt
              __typename
            }
            score {
              id
              event
              eventId
              eventImage
              matchNumber
              round
              teams {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              firstTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              secondTeam {
                teamId
                teamName
                teamLogo
                score
                __typename
              }
              tournament
              winner
              runnerUp
              agegroup
              courtNo
              record
              ppg
              rpe
              apg
              ranking
              city
              date
              time
              playerDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              firstTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              secondTeamDetails {
                teamId
                teamName
                teamLogo
                playerId
                playerName
                playerImage
                GP
                MIN
                PTS
                FGM
                FGA
                FGPERCENT
                THREEPM
                THREEPA
                THREEPPERCENT
                FTM
                FTA
                FTPERCENT
                OREB
                DREB
                REB
                AST
                TOV
                STL
                BLK
                PF
                FP
                DDTWO
                TDTHREE
                PlusMinus
                __typename
              }
              eventStatus
              roundId
              eventsId
              winnerId
              player {
                items {
                  id
                  tournamentId
                  scoreId
                  tournament {
                    id
                    userId
                    sessionId
                    isVerified
                    ext
                    participantName
                    tournamentId
                    team
                    teamID
                    matches {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    profile
                    teamName
                    message
                    dateOfBirth
                    isAdult
                    grade
                    email
                    isFirstYearPlaying
                    jerseyNo
                    transactionStatus
                    memberExpire
                    jerseySize
                    schoolName
                    paymentStatus
                    isMemberOfOtherTeam
                    clubName
                    secondaryPersonName
                    primaryPersonName
                    primaryCallPhoneNumber
                    secondaryCallPhoneNumber
                    primaryEmergencyContactEmail
                    secondaryEmergencyContactEmail
                    heardAboutLeague
                    healthConditions
                    parentFirstName
                    parentLastName
                    parentEmailAddress
                    parentPhoneNumber
                    parentBusinessPhoneNumber
                    parentHomePhoneNumber
                    parentCountry
                    signature
                    HEIGHT
                    WEIGHT
                    H_S
                    NBA
                    COLLEGE
                    phoneNumber
                    agegroup
                    createdAt
                    updatedAt
                    __typename
                  }
                  score {
                    id
                    event
                    eventId
                    eventImage
                    matchNumber
                    round
                    teams {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    firstTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    secondTeam {
                      teamId
                      teamName
                      teamLogo
                      score
                      __typename
                    }
                    tournament
                    winner
                    runnerUp
                    agegroup
                    courtNo
                    record
                    ppg
                    rpe
                    apg
                    ranking
                    city
                    date
                    time
                    playerDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    firstTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    secondTeamDetails {
                      teamId
                      teamName
                      teamLogo
                      playerId
                      playerName
                      playerImage
                      GP
                      MIN
                      PTS
                      FGM
                      FGA
                      FGPERCENT
                      THREEPM
                      THREEPA
                      THREEPPERCENT
                      FTM
                      FTA
                      FTPERCENT
                      OREB
                      DREB
                      REB
                      AST
                      TOV
                      STL
                      BLK
                      PF
                      FP
                      DDTWO
                      TDTHREE
                      PlusMinus
                      __typename
                    }
                    eventStatus
                    roundId
                    eventsId
                    winnerId
                    player {
                      items {
                        id
                        tournamentId
                        scoreId
                        createdAt
                        updatedAt
                        __typename
                      }
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const advertisementsByType = /* GraphQL */ `
  query AdvertisementsByType(
    $type: addType!
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertisementsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lg_img_url
        md_img_url
        sm_img_url
        xs_img_url
        page
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const discountCouponsByTypeAndValidTo = /* GraphQL */ `
  query DiscountCouponsByTypeAndValidTo(
    $type: discountType!
    $validTo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountCouponsByTypeAndValidTo(
      type: $type
      validTo: $validTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        couponValue
        discountOnUpto
        validFrom
        validTo
        isUserLimit
        uniqueProduct
        productCategories
        allProductsDiscount
        description
        isActive
        maxUsageLimit
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pricePagesByType = /* GraphQL */ `
  query PricePagesByType(
    $type: pagesPrice!
    $sortDirection: ModelSortDirection
    $filter: ModelPricePagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricePagesByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tournamentTeamsByTournamentId = /* GraphQL */ `
  query TournamentTeamsByTournamentId(
    $tournamentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTournamentTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tournamentTeamsByTournamentId(
      tournamentId: $tournamentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        imageURL
        scoreID
        agegroup
        location
        event
        eventId
        city
        tournamentId
        players {
          items {
            id
            userId
            sessionId
            isVerified
            ext
            participantName
            tournamentId
            team
            teamID
            matches {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            profile
            teamName
            message
            dateOfBirth
            isAdult
            grade
            email
            isFirstYearPlaying
            jerseyNo
            transactionStatus
            memberExpire
            jerseySize
            schoolName
            paymentStatus
            isMemberOfOtherTeam
            clubName
            secondaryPersonName
            primaryPersonName
            primaryCallPhoneNumber
            secondaryCallPhoneNumber
            primaryEmergencyContactEmail
            secondaryEmergencyContactEmail
            heardAboutLeague
            healthConditions
            parentFirstName
            parentLastName
            parentEmailAddress
            parentPhoneNumber
            parentBusinessPhoneNumber
            parentHomePhoneNumber
            parentCountry
            signature
            HEIGHT
            WEIGHT
            H_S
            NBA
            COLLEGE
            phoneNumber
            agegroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const winnersByTournamentId = /* GraphQL */ `
  query WinnersByTournamentId(
    $tournamentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWinnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    winnersByTournamentId(
      tournamentId: $tournamentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventId
        tournamentId
        matches {
          items {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        winner {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        runnerUpList
        runnerUp {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        totalRounds
        eventStatus
        rounds {
          items {
            id
            eventName
            eventId
            matches {
              items {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            winner {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            runnerUpList
            runnerUp {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            round
            eventStatus
            winnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roundsByWinnerId = /* GraphQL */ `
  query RoundsByWinnerId(
    $winnerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRoundsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roundsByWinnerId(
      winnerId: $winnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventId
        matches {
          items {
            id
            event
            eventId
            eventImage
            matchNumber
            round
            teams {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            firstTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            secondTeam {
              teamId
              teamName
              teamLogo
              score
              __typename
            }
            tournament
            winner
            runnerUp
            agegroup
            courtNo
            record
            ppg
            rpe
            apg
            ranking
            city
            date
            time
            playerDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            firstTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            secondTeamDetails {
              teamId
              teamName
              teamLogo
              playerId
              playerName
              playerImage
              GP
              MIN
              PTS
              FGM
              FGA
              FGPERCENT
              THREEPM
              THREEPA
              THREEPPERCENT
              FTM
              FTA
              FTPERCENT
              OREB
              DREB
              REB
              AST
              TOV
              STL
              BLK
              PF
              FP
              DDTWO
              TDTHREE
              PlusMinus
              __typename
            }
            eventStatus
            roundId
            eventsId
            winnerId
            player {
              items {
                id
                tournamentId
                scoreId
                tournament {
                  id
                  userId
                  sessionId
                  isVerified
                  ext
                  participantName
                  tournamentId
                  team
                  teamID
                  matches {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  profile
                  teamName
                  message
                  dateOfBirth
                  isAdult
                  grade
                  email
                  isFirstYearPlaying
                  jerseyNo
                  transactionStatus
                  memberExpire
                  jerseySize
                  schoolName
                  paymentStatus
                  isMemberOfOtherTeam
                  clubName
                  secondaryPersonName
                  primaryPersonName
                  primaryCallPhoneNumber
                  secondaryCallPhoneNumber
                  primaryEmergencyContactEmail
                  secondaryEmergencyContactEmail
                  heardAboutLeague
                  healthConditions
                  parentFirstName
                  parentLastName
                  parentEmailAddress
                  parentPhoneNumber
                  parentBusinessPhoneNumber
                  parentHomePhoneNumber
                  parentCountry
                  signature
                  HEIGHT
                  WEIGHT
                  H_S
                  NBA
                  COLLEGE
                  phoneNumber
                  agegroup
                  createdAt
                  updatedAt
                  __typename
                }
                score {
                  id
                  event
                  eventId
                  eventImage
                  matchNumber
                  round
                  teams {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  firstTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  secondTeam {
                    teamId
                    teamName
                    teamLogo
                    score
                    __typename
                  }
                  tournament
                  winner
                  runnerUp
                  agegroup
                  courtNo
                  record
                  ppg
                  rpe
                  apg
                  ranking
                  city
                  date
                  time
                  playerDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  firstTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  secondTeamDetails {
                    teamId
                    teamName
                    teamLogo
                    playerId
                    playerName
                    playerImage
                    GP
                    MIN
                    PTS
                    FGM
                    FGA
                    FGPERCENT
                    THREEPM
                    THREEPA
                    THREEPPERCENT
                    FTM
                    FTA
                    FTPERCENT
                    OREB
                    DREB
                    REB
                    AST
                    TOV
                    STL
                    BLK
                    PF
                    FP
                    DDTWO
                    TDTHREE
                    PlusMinus
                    __typename
                  }
                  eventStatus
                  roundId
                  eventsId
                  winnerId
                  player {
                    items {
                      id
                      tournamentId
                      scoreId
                      tournament {
                        id
                        userId
                        sessionId
                        isVerified
                        ext
                        participantName
                        tournamentId
                        team
                        teamID
                        profile
                        teamName
                        message
                        dateOfBirth
                        isAdult
                        grade
                        email
                        isFirstYearPlaying
                        jerseyNo
                        transactionStatus
                        memberExpire
                        jerseySize
                        schoolName
                        paymentStatus
                        isMemberOfOtherTeam
                        clubName
                        secondaryPersonName
                        primaryPersonName
                        primaryCallPhoneNumber
                        secondaryCallPhoneNumber
                        primaryEmergencyContactEmail
                        secondaryEmergencyContactEmail
                        heardAboutLeague
                        healthConditions
                        parentFirstName
                        parentLastName
                        parentEmailAddress
                        parentPhoneNumber
                        parentBusinessPhoneNumber
                        parentHomePhoneNumber
                        parentCountry
                        signature
                        HEIGHT
                        WEIGHT
                        H_S
                        NBA
                        COLLEGE
                        phoneNumber
                        agegroup
                        createdAt
                        updatedAt
                        __typename
                      }
                      score {
                        id
                        event
                        eventId
                        eventImage
                        matchNumber
                        round
                        tournament
                        winner
                        runnerUp
                        agegroup
                        courtNo
                        record
                        ppg
                        rpe
                        apg
                        ranking
                        city
                        date
                        time
                        eventStatus
                        roundId
                        eventsId
                        winnerId
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        winner {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        runnerUpList
        runnerUp {
          teamId
          teamName
          teamLogo
          score
          __typename
        }
        round
        eventStatus
        winnerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appointmentSchedulesByTutorId = /* GraphQL */ `
  query AppointmentSchedulesByTutorId(
    $tutorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentSchedulesByTutorId(
      tutorId: $tutorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appointmentSchedulesByAppointmentId = /* GraphQL */ `
  query AppointmentSchedulesByAppointmentId(
    $appointmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentSchedulesByAppointmentId(
      appointmentId: $appointmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tutorId
        appointmentId
        slots {
          startTime
          endTime
          __typename
        }
        maxStudents
        excludeDays
        excludeSlots {
          date
          slots {
            startTime
            endTime
            __typename
          }
          __typename
        }
        daily
        days
        dates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const player5x5sByScoreID = /* GraphQL */ `
  query Player5x5sByScoreID(
    $scoreID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayer5x5FilterInput
    $limit: Int
    $nextToken: String
  ) {
    player5x5sByScoreID(
      scoreID: $scoreID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const player5x5sByTeamID = /* GraphQL */ `
  query Player5x5sByTeamID(
    $teamID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayer5x5FilterInput
    $limit: Int
    $nextToken: String
  ) {
    player5x5sByTeamID(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        isVerified
        league
        agegroup
        ext
        participantName
        cityOfRegistration
        scoreID
        teamID
        profile
        teamName
        message
        dateOfBirth
        isAdult
        grade
        email
        jerseySize
        jerseyNo
        sessionId
        seasonIds
        schoolName
        transactionStatus
        memberExpire
        isMemberOfOtherTeam
        clubName
        secondaryPersonName
        primaryPersonName
        isFirstYearPlaying
        primaryCallPhoneNumber
        secondaryCallPhoneNumber
        primaryEmergencyContactEmail
        secondaryEmergencyContactEmail
        heardAboutLeague
        healthConditions
        parentFirstName
        parentLastName
        parentEmailAddress
        parentPhoneNumber
        parentBusinessPhoneNumber
        parentHomePhoneNumber
        parentCountry
        signature
        document
        HEIGHT
        WEIGHT
        H_S
        NBA
        COLLEGE
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playerScoresByTournamentId = /* GraphQL */ `
  query PlayerScoresByTournamentId(
    $tournamentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playerScoresByTournamentId(
      tournamentId: $tournamentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournamentId
        scoreId
        tournament {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        score {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playerScoresByScoreId = /* GraphQL */ `
  query PlayerScoresByScoreId(
    $scoreId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playerScoresByScoreId(
      scoreId: $scoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournamentId
        scoreId
        tournament {
          id
          userId
          sessionId
          isVerified
          ext
          participantName
          tournamentId
          team
          teamID
          matches {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          profile
          teamName
          message
          dateOfBirth
          isAdult
          grade
          email
          isFirstYearPlaying
          jerseyNo
          transactionStatus
          memberExpire
          jerseySize
          schoolName
          paymentStatus
          isMemberOfOtherTeam
          clubName
          secondaryPersonName
          primaryPersonName
          primaryCallPhoneNumber
          secondaryCallPhoneNumber
          primaryEmergencyContactEmail
          secondaryEmergencyContactEmail
          heardAboutLeague
          healthConditions
          parentFirstName
          parentLastName
          parentEmailAddress
          parentPhoneNumber
          parentBusinessPhoneNumber
          parentHomePhoneNumber
          parentCountry
          signature
          HEIGHT
          WEIGHT
          H_S
          NBA
          COLLEGE
          phoneNumber
          agegroup
          createdAt
          updatedAt
          __typename
        }
        score {
          id
          event
          eventId
          eventImage
          matchNumber
          round
          teams {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          firstTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          secondTeam {
            teamId
            teamName
            teamLogo
            score
            __typename
          }
          tournament
          winner
          runnerUp
          agegroup
          courtNo
          record
          ppg
          rpe
          apg
          ranking
          city
          date
          time
          playerDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          firstTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          secondTeamDetails {
            teamId
            teamName
            teamLogo
            playerId
            playerName
            playerImage
            GP
            MIN
            PTS
            FGM
            FGA
            FGPERCENT
            THREEPM
            THREEPA
            THREEPPERCENT
            FTM
            FTA
            FTPERCENT
            OREB
            DREB
            REB
            AST
            TOV
            STL
            BLK
            PF
            FP
            DDTWO
            TDTHREE
            PlusMinus
            __typename
          }
          eventStatus
          roundId
          eventsId
          winnerId
          player {
            items {
              id
              tournamentId
              scoreId
              tournament {
                id
                userId
                sessionId
                isVerified
                ext
                participantName
                tournamentId
                team
                teamID
                matches {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                profile
                teamName
                message
                dateOfBirth
                isAdult
                grade
                email
                isFirstYearPlaying
                jerseyNo
                transactionStatus
                memberExpire
                jerseySize
                schoolName
                paymentStatus
                isMemberOfOtherTeam
                clubName
                secondaryPersonName
                primaryPersonName
                primaryCallPhoneNumber
                secondaryCallPhoneNumber
                primaryEmergencyContactEmail
                secondaryEmergencyContactEmail
                heardAboutLeague
                healthConditions
                parentFirstName
                parentLastName
                parentEmailAddress
                parentPhoneNumber
                parentBusinessPhoneNumber
                parentHomePhoneNumber
                parentCountry
                signature
                HEIGHT
                WEIGHT
                H_S
                NBA
                COLLEGE
                phoneNumber
                agegroup
                createdAt
                updatedAt
                __typename
              }
              score {
                id
                event
                eventId
                eventImage
                matchNumber
                round
                teams {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                firstTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                secondTeam {
                  teamId
                  teamName
                  teamLogo
                  score
                  __typename
                }
                tournament
                winner
                runnerUp
                agegroup
                courtNo
                record
                ppg
                rpe
                apg
                ranking
                city
                date
                time
                playerDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                firstTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                secondTeamDetails {
                  teamId
                  teamName
                  teamLogo
                  playerId
                  playerName
                  playerImage
                  GP
                  MIN
                  PTS
                  FGM
                  FGA
                  FGPERCENT
                  THREEPM
                  THREEPA
                  THREEPPERCENT
                  FTM
                  FTA
                  FTPERCENT
                  OREB
                  DREB
                  REB
                  AST
                  TOV
                  STL
                  BLK
                  PF
                  FP
                  DDTWO
                  TDTHREE
                  PlusMinus
                  __typename
                }
                eventStatus
                roundId
                eventsId
                winnerId
                player {
                  items {
                    id
                    tournamentId
                    scoreId
                    tournament {
                      id
                      userId
                      sessionId
                      isVerified
                      ext
                      participantName
                      tournamentId
                      team
                      teamID
                      matches {
                        nextToken
                        __typename
                      }
                      profile
                      teamName
                      message
                      dateOfBirth
                      isAdult
                      grade
                      email
                      isFirstYearPlaying
                      jerseyNo
                      transactionStatus
                      memberExpire
                      jerseySize
                      schoolName
                      paymentStatus
                      isMemberOfOtherTeam
                      clubName
                      secondaryPersonName
                      primaryPersonName
                      primaryCallPhoneNumber
                      secondaryCallPhoneNumber
                      primaryEmergencyContactEmail
                      secondaryEmergencyContactEmail
                      heardAboutLeague
                      healthConditions
                      parentFirstName
                      parentLastName
                      parentEmailAddress
                      parentPhoneNumber
                      parentBusinessPhoneNumber
                      parentHomePhoneNumber
                      parentCountry
                      signature
                      HEIGHT
                      WEIGHT
                      H_S
                      NBA
                      COLLEGE
                      phoneNumber
                      agegroup
                      createdAt
                      updatedAt
                      __typename
                    }
                    score {
                      id
                      event
                      eventId
                      eventImage
                      matchNumber
                      round
                      teams {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      firstTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      secondTeam {
                        teamId
                        teamName
                        teamLogo
                        score
                        __typename
                      }
                      tournament
                      winner
                      runnerUp
                      agegroup
                      courtNo
                      record
                      ppg
                      rpe
                      apg
                      ranking
                      city
                      date
                      time
                      playerDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      firstTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      secondTeamDetails {
                        teamId
                        teamName
                        teamLogo
                        playerId
                        playerName
                        playerImage
                        GP
                        MIN
                        PTS
                        FGM
                        FGA
                        FGPERCENT
                        THREEPM
                        THREEPA
                        THREEPPERCENT
                        FTM
                        FTA
                        FTPERCENT
                        OREB
                        DREB
                        REB
                        AST
                        TOV
                        STL
                        BLK
                        PF
                        FP
                        DDTWO
                        TDTHREE
                        PlusMinus
                        __typename
                      }
                      eventStatus
                      roundId
                      eventsId
                      winnerId
                      player {
                        nextToken
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
