/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "adminApiForSfbayarea",
            "endpoint": "https://340femaid2.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "comptetive5x5PlayerApi",
            "endpoint": "https://k106ujqah2.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "contactApi",
            "endpoint": "https://kw4478ntg8.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "handleClinicAndTrainingProgram",
            "endpoint": "https://ghibd25gfk.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "handleComptetivePlayers",
            "endpoint": "https://xn3fdo8lc5.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "handleWorkWithUsAndPartnerApiForSfbayarea",
            "endpoint": "https://y11ooys483.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "inviteComptetive5x5PlayerApi",
            "endpoint": "https://hc8sz1mr9k.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "invitePlayeAPi",
            "endpoint": "https://p4jf3scaxb.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "orderApi",
            "endpoint": "https://ya2fazupcj.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "signupPlayer5x5Api",
            "endpoint": "https://q3onfnp0vd.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "signupTryout5X5Api",
            "endpoint": "https://6f7r651jxi.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "signupTryoutApi",
            "endpoint": "https://n5xv596hbj.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "signupother",
            "endpoint": "https://eq258q1oz6.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "signupplayers",
            "endpoint": "https://86nghzt0e7.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        },
        {
            "name": "userApiForSfbayarea",
            "endpoint": "https://tthb2hw3r9.execute-api.us-west-1.amazonaws.com/prod",
            "region": "us-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vu3hvtn5wrbjnfkwycdn4vvzti.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-c3eunlrssjh4ddw3v4eyak2yuy",
    "aws_cognito_identity_pool_id": "us-west-1:582f1cd6-20a9-4014-a1d5-bde6b9e2a24a",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_evtPf3pJv",
    "aws_user_pools_web_client_id": "7epb752muggpehaelfsomh0f9o",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "GENDER",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sfbayareas3bucket5965d-prod",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
