import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries"; // Import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  CREATE_PLAYER_AGE_REQUEST,
  CREATE_PLAYER_AGE_SUCCESS,
  CREATE_PLAYER_AGE_FAIL,
  GET_PLAYER_AGE_REQUEST,
  GET_PLAYER_AGE_SUCCESS,
  GET_PLAYER_AGE_FAIL,
  LIST_PLAYER_AGE_REQUEST,
  LIST_PLAYER_AGE_SUCCESS,
  LIST_PLAYER_AGE_FAIL,
  UPDATE_PLAYER_AGE_REQUEST,
  UPDATE_PLAYER_AGE_SUCCESS,
  UPDATE_PLAYER_AGE_FAIL,
  DELETE_PLAYER_AGE_REQUEST,
  DELETE_PLAYER_AGE_SUCCESS,
  DELETE_PLAYER_AGE_FAIL,
  DELETE_PLAYER_AGE_RESET,
  CLEAR_PLAYER_AGE_ERRORS,
} from "../constants/LeagueInfoConstants";
import getLinks from "../../utils/getLinks";

export const getleagueAgeInfo = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_PLAYER_AGE_REQUEST });
    const responsePlayer = await API.graphql({
      query: queries.listPlayers,
      variables: {
        filter: {
          isVerified: {
            eq: true,
          },
        },
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    const players = responsePlayer?.data?.listPlayers?.items;
    const responseTeams = await API.graphql(
      graphqlOperation(queries.listTeams)
    );

    const dataTeams = responseTeams?.data?.listTeams?.items || [];
    const updatedTeams = await Promise.all(
      dataTeams?.map(async (product) => {
        try {
          const signedUrl = await getLinks(product?.img_url);
          return { ...product, img_url: signedUrl };
        } catch (error) {
          console.error("Error fetching Team image URL", error);
          return product;
        }
      })
    );
    const teams = updatedTeams;

    const response = await API.graphql(graphqlOperation(queries.listCities));
    let data = {};
    // const initialData = {
    //   "5-7": 0,
    //   "8-9": 0,
    //   "10-12": 0,
    //   "13-14": 0,
    //   "15-17": 0,
    //   "18-40": 0,
    //   "40+": 0,
    // };

    const initialData = {
      "u7": 0,
      "u8": 0,
      "u9": 0,
      "u10": 0,
      "u11": 0,
      "u12": 0,
      "u13": 0,
      "u14": 0,
      "u15": 0,
      "u17": 0,
      "u18": 0,
      "u19-u39": 0,
      "over u40": 0
    };

    const getAge = (date) => {
      const dob = new Date(date);
      const now = new Date();
      const ageInMilliseconds = now - dob;
      const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
      const age = Math.floor(ageInYears);
      return age;
    };

    const newData = { ...data }; // Copy the existing data

    teams?.forEach((team) => {
      if (!newData[team?.name]) {
        newData[team?.name] = { ...initialData };
      }
    });

    console.log("verifyPlayer", players);

    players?.forEach((player) => {
      const age = getAge(player?.dateOfBirth);
      const team = player?.teamName;
      if (!newData[team]) {
        newData[team] = { ...initialData };
      }
      // if (age >= 5 && age <= 7) {
      //   newData[team]["5-7"]++;
      // } else if (age >= 8 && age <= 9) {
      //   newData[team]["8-9"]++;
      // } else if (age >= 10 && age <= 12) {
      //   newData[team]["10-12"]++;
      // } else if (age >= 13 && age <= 14) {
      //   newData[team]["13-14"]++;
      // } else if (age >= 15 && age <= 17) {
      //   newData[team]["15-17"]++;
      // } else if (age >= 18 && age <= 40) {
      //   newData[team]["18-40"]++;
      // } else {
      //   newData[team]["40+"]++;
      // }

      if (age >= 5 && age <= 7) {
        newData[team]["u7"]++;
      } else if (age == 8) {
        newData[team]["u8"]++;
      } else if (age == 9) {
        newData[team]["u9"]++;
      } else if (age == 10) {
        newData[team]["u10"]++;
      } else if (age == 11) {
        newData[team]["u11"]++;
      } else if (age == 12) {
        newData[team]["u12"]++;
      } else if (age == 13) {
        newData[team]["u13"]++;
      } if (age == 14) {
        newData[team]["u14"]++;
      } else if (age == 15) {
        newData[team]["u15"]++;
      } else if (age == 16) {
        newData[team]["u16"]++;
      } else if (age == 17) {
        newData[team]["u17"]++;
      } else if (age == 18) {
        newData[team]["u18"]++;
      } else if (age >= 19 && age <= 39) {
        newData[team]["u19-u39"]++;
      } else {
        newData[team]["over u40"]++;
      }
    });

    data = newData;

    // Convert data to an array of objects
    const dataArray = Object.keys(data).map((team) => ({
      team,
      ...data[team],
    }));

    dispatch({
      type: LIST_PLAYER_AGE_SUCCESS,
      payload: dataArray,
    });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: LIST_PLAYER_AGE_FAIL,
      payload: errorMessage,
    });
  }
};

// export const getCityDetails = (cityId) => async (dispatch) => {
//   try {
//     dispatch({ type: GET_PLAYER_AGE_REQUEST });

//     const response = await API.graphql(
//       graphqlOperation(queries.getCity, { id: cityId })
//     );

//     const data = response?.data.getItem;
//     dispatch({
//       type: GET_CITY_REQUEST,
//       payload: data,
//     });
//   } catch (error) {
//     const errorMessage =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;

//     dispatch({
//       type: GET_CITY_FAIL,
//       payload: errorMessage,
//     });
//   }
// };

//add a new product
// export const addProduct = (productData,images) => async (dispatch) => {
//     try {
//       dispatch({ type: ADD_PRODUCT_REQUEST });

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       // const config = { headers: { "Content-Type": "multipart/form-data" } };

//       const { data } = await axios.post("/api/v1/admin/new/product", productData, config);

//       dispatch({
//         type: ADD_PRODUCT_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       const errorMessage =
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: ADD_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const updateProduct = (eventId, productData) => async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PRODUCT_REQUEST });

//       const config = {
//         headers: { "Content-Type": "application/json" },
//       };

//       const { data } = await axios.put(
//         `/api/v1/admin/product/${eventId}`,
//         productData,
//         config
//       );

//       dispatch({
//         type: UPDATE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: UPDATE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

// export const deleteProduct = (eventId) => async (dispatch) => {
//     try {
//       dispatch({ type: DELETE_PRODUCT_REQUEST });

//       const { data } = await axios.delete(`/api/v1/admin/product/${eventId}`);

//       dispatch({
//         type: DELETE_PRODUCT_SUCCESS,
//         payload: data.success,
//       });
//     } catch (error) {
//           const errorMessage =
//             error.response && error.response.data.message
//               ? error.response.data.message
//               : error.message;

//       dispatch({
//         type: DELETE_PRODUCT_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const clearLeagueInfoErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PLAYER_AGE_ERRORS });
};
