/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import getLink from "../utils/getLinks";
import { useDispatch, useSelector } from "react-redux";

import deleteFile from "../utils/deleteFile";
import * as queries from "../graphql/queries";
import uploadFile from "../utils/uploadFile";
import * as mutations from "../graphql/mutations";
import useGetAllTeams from "./getTeam5x5sLists";

const useHandlePagintationPlayers5x5 = () => {
  const [playersDetails, setPlayerDetails] = useState();
  const { teamslist } = useGetAllTeams();

  const [alreadyPlayer, setAlreadyPlayer] = useState(null);
  const location = useLocation();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [verifiedPlayers, setVerifiedPlayers] = useState([]);
  const [notVerifiedPlayers, setNotVerifiedPlayers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchImageFromCacheWithExpiration = async (url) => {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      const { timestamp, imageUrl } = JSON.parse(cachedData);
      const currentTime = Date.now();

      // Check if the stored image is older than 300 seconds (5 minutes)
      if (currentTime - timestamp < 300000) {
        return imageUrl; // Use the cached image URL
      }
    }
    // If the image is older than 300 seconds or not found, fetch a new one
    const signedUrl = await loadImageAndCache(url);
    if (signedUrl) {
      // Store the new image URL with the current timestamp
      const dataToCache = JSON.stringify({
        timestamp: Date.now(),
        imageUrl: signedUrl,
      });
      localStorage.setItem(url, dataToCache);
      return signedUrl;
    }
    return null;
  };
  const loadImageAndCache = async (url) => {
    try {
      const signedUrl = await getLink(url);
      localStorage.setItem(url, signedUrl); // Store image data in cache
      return signedUrl;
    } catch (error) {
      console.error("Error fetching or caching image:", error);
      return null;
    }
  };

  // const deletePlayers = async (id) => {
  //   try {
  //     setPlayerList((prevPlayersList) =>
  //       prevPlayersList.filter((item) => item.id !== id)
  //     );
  //     setNotVerifiedPlayers((prevPlayersList) =>
  //       prevPlayersList.filter((item) => item.id !== id)
  //     );
  //     setVerifiedPlayers((prevPlayersList) =>
  //       prevPlayersList.filter((item) => item.id !== id)
  //     );
  //     setLoading(true);
  //     const res = await API.graphql(
  //       graphqlOperation(queries.getPlayer, { id }),
  //       {
  //         authMode: GRAPHQL_AUTH_MODE.API_KEY,
  //       }
  //     );

  //     const isDeleted = await deleteFile(res?.data?.getPlayer?.img_url);
  //     const isDel = await deleteFile(res?.data?.getPlayer?.document);

  //     // if (isDeleted && isDel) {
  //     const response = await API.graphql(
  //       graphqlOperation(mutations.deletePlayer, { input: { id } }),
  //       {
  //         authMode: GRAPHQL_AUTH_MODE.API_KEY,
  //       }
  //     );

  //     console.log(response);
  //     toast.success("Players Deleted Successfully!");

  //     // Remove deleted Verified from the playersList

  //     // Remove deleted images from local storage
  //     localStorage.removeItem(res?.data?.deletePlayer?.profile);
  //     // }else{
  //     // toast.error('Error Deleting Players');

  //     // }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error("Something Went Wrong: Check Console");
  //     console.error("Error Deleting Players:", error);
  //   }
  // };

  const updatePlayers = async (data) => {
    try {
      console.log("data", data);
      delete data?.documentLink;
      delete data?.document;
      delete data?.memberExpire;
      delete data?.profileLink;
      if (!alreadyPlayer) {
        window.location.href = "/";
      }
      let updatedDataWithNewImage = { ...data };
      if (data?.newProfile && data?.newProfile !== "") {
        const delteIamge = await deleteFile(data?.profile);
        if (delteIamge) {
          const updatedImage = await uploadFile(data?.newProfile);
          delete data?.newProfile;
          updatedDataWithNewImage = {
            ...data,
            profile: updatedImage,
          };
        }
      } else {
        delete updatedDataWithNewImage?.newProfile;
      }

      setLoading(true);
      const id = data?.id;
      const response = await API.graphql(
        graphqlOperation(mutations.updatePlayer, {
          input: { id, ...updatedDataWithNewImage },
        }),
        {
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        }
      );
      const updatedData = response?.data?.updatePlayer;

      const cachedImgUrl = await fetchImageFromCacheWithExpiration(
        updatedData?.profile
      );

      let render_img_url = cachedImgUrl;

      if (!cachedImgUrl) {
        render_img_url = await loadImageAndCache(updatedData?.profile);
      }
      const document = await getLink(updatedData?.document);

      const updatedDatas = {
        ...updatedData,
        profileLink: render_img_url,
        documentLink: document,
      };

      setPlayerDetails(updatedDatas);

      toast.success("Players Updated Successfully!");
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error("Error updating Verified details:", error);
      toast.error("Error updating Verified details");
      throw error; // Propagate the error for handling in the component
    }
  };

  const registerAsPlayer5x5 = async (data) => {
    try {
      setLoading(true);
      const teamName = data.teamName;
      const isTeam = teamslist?.filter((team) => team.name === teamName);
      console.log("!isTeam", isTeam, teamslist);
      const playerALready = await fetchAlreadyRegistered(user);
   
      if (isTeam.length === 0) {
        console.log('enterrre');
        const resp = await API.graphql({
          query: mutations.createTeam5x5,
          variables: {
            input: {
              name: teamName,
              agegroup: data.agegroup,
              location: data.cityOfRegistration,
              scoreID: [],
              imageURL: data.teamImage,
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const newTeam = resp?.data?.createTeam5x5;
        const teamID = newTeam?.id;
        data.teamID = teamID;
        // Further logic after team creation (if needed)
      } else {
        const teamID = isTeam[0]?.id;
        data.teamID = teamID;
      }

      delete data.teamImage;
      console.log("check program", data, user);

      if (alreadyPlayer || playerALready) {
        toast.error("Already registered");
        setLoading(false);
        window.location.href = "/";

        return;
      }
      // const profileImage = await uploadFile(data.profile);
      // const document = await uploadFile(data.document);

      const checkoutPayload = {
        body: {
          // success_url: "http://localhost:3000/order-success",
          // cancel_url: "http://localhost:3000/cart",
          success_url: "https://www.sfbayareabasketball.com/payment-success",
          // success_url: "http://localhost:3000/payment-success",
          cancel_url: "https://www.sfbayareabasketball.com/payment-failure",

          ...data,
          userId: user,

          // document: document,
          // profile: profileImage,
        },
      };
      console.log("check program2");

      // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "signupPlayer5x5Api",
        "/signup/player5x5",
        checkoutPayload
      );

      window.location.href = response?.url;

      console.log("check program3");

      // toast.success("Registered Successfully!");
      setLoading(false);
      // window.location ='/';
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setLoading(false);

      throw new Error(errorMessage);
    }
  };

 
  const fetchAlreadyRegistered = async (id) => {
    try {
      setLoading(true);

      const response = await API.graphql({
        query: queries.listPlayer5x5s,
        variables: {
          filter: {
            userId: {
              eq: id,
            },
          },
        },
      });

      // Send a request to the Stripe API to create a checkout session
      // const response = await API.post("signup", "/signup", checkoutPayload);
      // const response = await API.graphql(
      //   graphqlOperation(queries.getPlayer, { id })
      // );
      const playerData = response?.data?.listPlayer5x5s?.items;
      console.log("palyerdata", playerData);
      if (
        playerData !== null &&
        playerData !== undefined &&
        playerData.length !== 0
      ) {
        setAlreadyPlayer(true);
        setLoading(false);
        if (location.pathname.includes("signupleague5x5")) {
          window.location.href = "/";
        }
        return true;
      } else {
        setAlreadyPlayer(false);
        setLoading(false);
      }

      return false;
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  const getPlayerDetails = async (playerId) => {
    try {
      setLoading(true);

      const playerResponse = await API.graphql({
        query: queries.getPlayer,
        variables: {
          id: playerId,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      const playerData = playerResponse?.data?.getPlayer;
      const cachedImgUrl = await fetchImageFromCacheWithExpiration(
        playerData?.profile
      );

      let render_img_url = cachedImgUrl;

      if (!cachedImgUrl) {
        render_img_url = await loadImageAndCache(playerData?.profile);
      }
      const document = await getLink(playerData?.document);

      const updatedData = {
        ...playerData,
        profileLink: render_img_url,
        documentLink: document,
      };
      setPlayerDetails(updatedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw new Error(error);
    }
  };

  const fetchPlayer = async (teamName) => {
    try {
      setLoading(true);

      const resp = await API.graphql({
        query: queries.listPlayer5x5s,
        variables: {
          filter: {
            teamName: {
              eq: teamName,
            },
          },
        },
      });

      const playerS = resp?.data?.listPlayer5x5s?.items;
      setLoading(false);
      return playerS;
    } catch (error) {
      setLoading(false);

      throw new Error(error);
    }
  };

  const verifyPayment = async () => {
    try {
      const checkoutPayload = {
        body: {
          // success_url: "http://localhost:3000/order-success",
          // cancel_url: "http://localhost:3000/cart",
          userId: user,
        },
      };
      console.log("checkoutPayload", checkoutPayload);

      // // Send a request to the Stripe API to create a checkout session
      const response = await API.post(
        "signupPlayer5x5Api",
        "/signup",
        checkoutPayload
      );
    } catch (error) {
      console.log("Error Verifying Payment", error);
      throw new Error("Error Verifying Payment");
    }
  };

  useEffect(() => {
    console.log("user", user);
    if (user) {
      getPlayerDetails(user);
      fetchAlreadyRegistered(user);
    }
  }, [user]);

  return {
    notVerifiedPlayers,
    verifiedPlayers,
    loading,
    playersDetails,
    // addEvent,
    fetchAlreadyRegistered,
    updatePlayers,
    alreadyPlayer,
    registerAsPlayer5x5,
    getPlayerDetails,
    fetchPlayer,
    verifyPayment,
  };
};

export default useHandlePagintationPlayers5x5;
