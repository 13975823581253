import { Table } from "flowbite-react";
import Loader from "../Loader";
import { useEffect } from "react";
import {
  clearLeagueInfoErrors,
  getleagueAgeInfo,
} from "../../redux/actions/leagueInfoAction";
import {
  clearPlayerErrors,
  getAllPlayer,
} from "../../redux/actions/PlayerAction";
import { useDispatch, useSelector } from "react-redux";

const Age = {
  ages: [
    { value: "u7", "label": "U7" },
    { value: "u8", "label": "U8" },
    { value: "u9", "label": "U9" },
    { value: "u10", "label": "U10" },
    { value: "u11", "label": "U11" },
    { value: "u12", "label": "U12" },
    { value: "u13", "label": "U13" },
    { value: "u14", "label": "U14" },
    { value: "u15", "label": "U15" },
    { value: "u17", "label": "U17" },
    { value: "u18", "label": "U18" },
    { value: "19 and over", "label": "19 and over" },
    { value: "19-39", "label": "19-39" },
    { value: "over 40", "label": "Over 40" }
  ]
  ,
};

const LeagueCityTable = ({ teamslist, loading }) => {
  if (loading) {
    return <Loader />;
  }



  // Organize teams by age group
  const teamsByAge = {};
  teamslist?.forEach((team) => {
    if (!teamsByAge[team.agegroup]) {
      teamsByAge[team.agegroup] = [];
    }
    teamsByAge[team.agegroup].push(team);
  });

  console.log("Table Teams list", teamslist)


  return (
    <div className="overflow-x-auto">
      <Table className="text-center">
        <Table.Head>
          <Table.HeadCell className="min-w-[30px] bg-white whitespace-nowrap border px-1 py-1">
            Age Group
          </Table.HeadCell>
          {Array.isArray(teamslist) && teamslist.length > 0
            ? teamslist.length > 0 && teamslist?.map((item, index) => (
              <Table.HeadCell
                key={index}
                className="min-w-[30px] bg-white border px-2 py-2"
              >
                Team Name
              </Table.HeadCell>
            ))
            :
            // Age.ages.map((item, index) => (
            <Table.HeadCell
              //  key={index}
              className="min-w-[30px] bg-white border px-2 py-2"
            >
              Team Name
            </Table.HeadCell>
            //  ))
          }
        </Table.Head>
        <Table.Body className="divide-y">
          {Age.ages.map((age, index) => (
            <Table.Row
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              key={index}
            >
              {/* Display the age label */}
              <Table.Cell className="whitespace-nowrap px-1 py-1 text-center border font-medium text-gray-900 dark:text-white min-w-[30]">
                {age.label} {/* Display the label for each age group (U7, U8, etc.) */}
              </Table.Cell>

              {/* Check if teamsByAge has data for the specific age group (age.value) */}
              {teamsByAge[age.value]
                ? new Array(
                    Math.max(
                      teamslist.length > 0 ? teamslist.length : Age.ages.length,
                      teamsByAge[age.value].length
                    )
                  )
                    .fill("")
                    .map((_, teamIndex) => (
                      <Table.Cell
                        key={teamIndex}
                        className="min-w-[30px] text-center border px-2 py-2"
                      >
                        {/* Display team names for each age group */}
                        {teamsByAge[age.value][teamIndex]
                          ? teamsByAge[age.value][teamIndex].name
                          : ""}
                      </Table.Cell>
                    ))
                : new Array(teamslist.length > 0 ? teamslist.length : 1)
                    .fill("")
                    .map((_, emptyIndex) => (
                      <Table.Cell
                        key={emptyIndex}
                        className="min-w-[30px] text-center border px-2 py-2"
                      />
                    ))}
            </Table.Row>
          ))}
        </Table.Body>

      </Table>
    </div>
  );
};

export default LeagueCityTable;
