import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import SignUpDetail from "../../Components/SignUpDetail";
import { useState, useEffect } from "react";
import useGetAllCity from "../../hooks/getCityList";
import useGetAllTeams from "../../hooks/getTeamsLists";
import getLeaguesList from "../../hooks/getLeagues";
import { ToastContainer, toast } from "react-toastify";
import TypoBu from "../../Components/TypoBu";
import { Link, NavLink, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useAlreadySignup from "../../hooks/alreadySignupAsLeagues";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RenderShop from "../../Components/Shop/RenderShop";
import Loader from "../../Components/Loader";

import { registerAsCamp } from "../../redux/actions/CampAction";
import { clearUserErrors, loadUser } from "../../redux/actions/UserAction";
import { clearTeamErrors, getAllTeams } from "../../redux/actions/TeamAction";
import { clearCityErrors, getAllCity } from "../../redux/actions/CityAction";
import {
  clearEventsErrors,
  getAllEvents,
} from "../../redux/actions/EventsAction";
import useGetAllCamps from "../../hooks/getAllCamps";
import useGetAllCampCourses from "../../hooks/getAllCampCourses";

import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import useTeamCityValidation from "../SignUpLeague/useTeamCityValidation";
import { useCampEvents } from "../../hooks/getCampsEvents";
import Calendar from "../../Components/Calendar/Calendar";

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const experiences = [
  { label: "In The Park", value: "In The Park" },
  { label: "Organized Recreational", value: "Organized Recreational" },
  { label: "Middle School", value: "Middle School" },
  { label: "High School", value: "High School" },
  { label: "College", value: "College" },
  { label: "Pro", value: "Pro" },
];

const SignUpCamp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    userDetails,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);
  const {
    camp,
    loading: campLoading,
    created,
    error: campError,
  } = useSelector((state) => state.camp);
  const { loading: fetchingCampsEvents, campsEventsList } = useCampEvents();
  const { camps, loading } = useGetAllCamps();
  const { campCourses, loading: courseLoading } = useGetAllCampCourses();
  const { citylist } = useGetAllCity();
  const { campslist } = useGetAllTeams();
  const { leagues } = getLeaguesList();
  const [itemlist, setItemList] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agegroup, setAgegroup] = useState(userDetails["age"] || "");
  const [ageGroupError, setAgeGroupError] = useState("");
  const [filterCampsList, setFilterCampsList] = useState([]);
  const [email, setEmail] = useState(userDetails["email"] || "");
  const [cellPhone, setCellPhone] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [ext, setExt] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [country, setCountry] = useState("");
  const [participant, setParticipant] = useState(
    userDetails["name"] || ""
  );
  const [cityOfRegistration, setCityOfRegistration] = useState("");
  const [nameOfSelectedCampEvent, setNameOfSelectedCampEvents] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isParentGuardian, setIsParentGuardian] = useState(false);
  const [grade, setGrade] = useState("");
  const [jerseySize, setJerseySize] = useState("");
  const [isMemberOfLeague, setIsMemberOfLeague] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [isMemberOfTeamOrClub, setIsMemberOfTeamOrClub] = useState(false);
  const [clubName, setClubName] = useState("");
  const [secondaryEmergencyContact, setSecondaryEmergencyContact] =
    useState("");
  const [primaryEmergencyContact, setPrimaryEmergencyContact] = useState("");
  const [event, setEvent] = useState("");
  const [primaryEmergencyContactEmail, setPrimaryEmergencyContactEmail] =
    useState("");
  const [primaryCallPhoneNumber, setPrimaryCallPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    userDetails["phone_number"] || ""
  );
  const [secondaryEmergencyContactEmail, setSecondaryEmergencyContactEmail] =
    useState("");
  const [secondaryCallPhoneNumber, setSecondaryCallPhoneNumber] = useState("");
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [healthConditions, setHealthConditions] = useState(false);
  const [agreeRelease, setAgreeRelease] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);
  const [signatureType, setSignatureType] = useState(""); // Default value
  const [electronicSignature, setElectronicSignature] = useState("");
  const [profile, setProfile] = useState(""); // You can use null or an initial value
  // const { signUpLeagues, loading } = useCreateSignupLeagues();
  const [updatedTeams, setUpdatedTeams] = useState(campslist || []);

  // const { alreadyUserLeagues } = useAlreadySignup();
  const [participantError, setParticipantError] = useState("");
  const [cityOfRegistrationError, setCityOfRegistrationError] = useState("");
  const [eventError, setEventError] = useState(""); // Add validation error state
  const [nameOfSelectedCampEventError, setNameOfSelectedCampEventsError] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cellPhoneError, setCellPhoneError] = useState("");
  const [businessPhoneError, setBusinessPhoneError] = useState("");
  const [extError, setExtError] = useState("");
  const [homePhoneError, setHomePhoneError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [campTypeError, setCampTypeError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [isParentGuardianError, setIsParentGuardianError] = useState("");
  const [parentEmailAddress, setParentEmailAddress] = useState("");

  const [gradeError, setGradeError] = useState("");
  const [schoolNameError, setSchoolNameError] = useState("");
  const [isMemberOfLeagueError, setIsMemberOfLeagueError] = useState("");
  const [newTeamImage, setNewTeamIamge] = useState(""); // You can use null or an initial value
  const [newTeam, setNewTeam] = useState("");
  const { validateTeam, isTeamValid, errorMessage } = useTeamCityValidation();

  const [jerseySizeError, setJerseySizeError] = useState("");
  const [isMemberOfTeamOrClubError, setIsMemberOfTeamOrClubError] =
    useState("");
  const [clubNameError, setClubNameError] = useState("");
  const [secondaryEmergencyContactError, setSecondaryEmergencyContactError] =
    useState("");
  const [
    secondaryEmergencyContactEmailError,
    setSecondaryEmergencyContactEmailError,
  ] = useState("");
  const [secondaryCallPhoneNumberError, setSecondaryCallPhoneNumberError] =
    useState("");
  const [primaryEmergencyContactError, setPrimaryEmergencyContactError] =
    useState("");
  const [
    primaryEmergencyContactEmailError,
    setPrimaryEmergencyContactEmailError,
  ] = useState("");
  const [parentEmailAddressError, setParentEmailAddressError] = useState("");

  const [primaryCallPhoneNumberError, setPrimaryCallPhoneNumberError] =
    useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [howDidYouHearError, setHowDidYouHearError] = useState("");
  const [healthConditionsError, setHealthConditionsError] = useState("");
  const [agreeReleaseError, setAgreeReleaseError] = useState("");
  const [agreeActiveError, setAgreeActiveError] = useState("");
  const [signatureTypeError, setSignatureTypeError] = useState("");
  const [electronicSignatureError, setElectronicSignatureError] = useState("");
  // State variables
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [experience, setExperience] = useState("");

  // Error variables
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [campType, setCampType] = useState("");
  const [course, setCourse] = useState("");
  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
    setParticipantError(""); // Reset error
  };
  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
    setDateOfBirthError("");
  };
  const handleCityOfRegistrationChange = (e) => {
    setCityOfRegistration(e.target.value);
    const city = e.target.value;
    const matchingEvents = campsEventsList.filter(
      (events) => events.cityOfRegistration === city
    );

    // console.log("matchingTeams", city, matchingEvents);
    // If there are matching camps, add them to updatedTeams
    if (matchingEvents.length > 0) {
      setFilterCampsList([...matchingEvents]);
    } else {
      setFilterCampsList([]);
    }

    setCityOfRegistrationError(""); // Reset error
  };
  const handleEventChange = (e) => {
    setEvent(e.target.value);
    setEventError(""); // Reset error
  };
  const handleCampsChange = (e) => {
    setCampType(e.target.value);
    setCampTypeError("");
  };

  const handleCourseChange = (e) => {
    // console.log("EVENT", e.target.value);
    setCourse(e.target.value);
    setCourseError("");
  };
  const handleAgeGroup = (e) => {
    const selectedAgeGroup = e.target.value;
    setAgegroup(selectedAgeGroup);
    const matchingTeams = campslist.filter(
      (camp) =>
        camp.location === cityOfRegistration &&
        camp.agegroup === selectedAgeGroup
    );

    // console.log("ageGroup", campslist, selectedAgeGroup, matchingTeams);
    // If there are matching camps, add them to updatedTeams
    if (matchingTeams.length > 0) {
      setUpdatedTeams([...matchingTeams]);
    } else {
      setUpdatedTeams([]);
    }

    setAgeGroupError("");
  };

  const handleNameOfSelectedCampsEvents = (e) => {
    setNameOfSelectedCampEvents(e.target.value);
    setEvent(e.target.value);
    setNameOfSelectedCampEventsError(""); // Reset error
  };
  const handleProfile = async (e) => {
    const file = e.target.files[0];
    // Handle file upload here if needed
    if (file) {
      setProfile(file);
    } else {
      setProfile(null);
    }
    setProfileError("");
  };
  // onChange functions
  const handleHeightChange = (e) => {
    setHeight(e.target.value);
    setHeightError("");
  };
  const createNewTeam = async (e) => {
    setNewTeam(e.target.value);
    validateTeam(cityOfRegistration, e.target.value);
    setNameOfSelectedCampEventsError(""); // Reset error
  };
  const handleWeightChange = (e) => {
    setWeight(e.target.value);
    setWeightError("");
  };

  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
    setExperienceError("");
  };

  // console.log('dateOfBirth',dateOfBirth)
  const handleRegister = async (e) => {
    e.preventDefault();
    let hasErrors = false; // Validation functions

    if (!campType) {
      console.log("camptype");
      setCampTypeError("Camp is required.");
      hasErrors = true;
    }
    if (!course) {
      console.log("course");

      setCourseError("Course is required.");
      hasErrors = true;
    }
    if (!height) {
      console.log("height");

      setHeightError("Height is required.");
      hasErrors = true;
    }

    if (!weight) {
      console.log("weight");

      setWeightError("Weight is required.");
      hasErrors = true;
    }

    if (!experience) {
      console.log("experrience");

      setExperienceError("Experience is required.");
      hasErrors = true;
    }

    // if (!agegroup) {
    //   setAgeGroupError("Please enter the Age Group");
    //   hasErrors = true;
    // }

    if (!firstName) {
      console.log("first nae");

      setFirstNameError("First Name is required.");
      hasErrors = true;
    }

    if (!email) {
      console.log("email");

      setEmailError("Email address is required.");
      hasErrors = true;
    }
    if (!lastName) {
      console.log("last");

      setLastNameError("Last Name is required.");
      hasErrors = true;
    }

    if (!cellPhone) {
      console.log("cell phn");

      setCellPhoneError("Cell Phone is required.");
      hasErrors = true;
    }

    if (!businessPhone) {
      console.log("business phone");

      setBusinessPhoneError("Business Phone is required.");
      hasErrors = true;
    }
    if (!ext) {
      console.log("ext");

      setExtError("Ext is required.");
      hasErrors = true;
    }
    if (!homePhone) {
      console.log("homep phone");

      setHomePhoneError("Home Phone is required.");
      hasErrors = true;
    }
    if (!participant) {
      console.log("participant");

      setParticipantError("Participant is required.");
      hasErrors = true;
    }

    if (!cityOfRegistration) {
      console.log("cityy");

      setCityOfRegistrationError("City of Registration is required.");
      hasErrors = true;
    }

    // if (!event) {
    //   setEventError("Event is required.");
    //   hasErrors = true;
    // }

    if (!nameOfSelectedCampEvent) {
      console.log("cmp evnt");

      setNameOfSelectedCampEventsError("Camp Signing Up For is required.");
      hasErrors = true;
    }
    if (!dateOfBirth) {
      console.log("dob");

      setDateOfBirthError("Date of Birth is required.");
      hasErrors = true;
    }

    if (!jerseySize) {
      console.log("size");

      setJerseySizeError("Jersey Size is required.");
      hasErrors = true;
    }
    if (!schoolName) {
      console.log("school name");

      setSchoolNameError("School Name is required.");
      hasErrors = true;
    }
    if (!clubName) {
      console.log("clb name");

      setClubNameError("Club Name is required.");
      hasErrors = true;
    }

    if (!profile) {
      console.log("profile");

      setProfileError("Profile is required.");
      hasErrors = true;
    }
    if (!country) {
      console.log("country");

      setCountryError("Country is required.");
      hasErrors = true;
    }

    if (!isParentGuardian) {
      console.log("is parent guard");

      setIsParentGuardianError("Is Parent Guardian is required.");
      hasErrors = true;
    }

    if (!grade) {
      console.log("grade");

      setGradeError("Grade is required.");
      hasErrors = true;
    }
    if (!parentEmailAddress) {
      console.log("parent emal");

      setParentEmailAddressError("Email Address is required.");
      hasErrors = true;
    }

    if (!isMemberOfLeague) {
      console.log("isMemberOfLeague");
      setIsMemberOfLeagueError("Is Member Of League is required.");
      hasErrors = true;
    }

    if (!isMemberOfTeamOrClub) {
      console.log("isMemberOfTeamOrClub");

      setIsMemberOfTeamOrClubError("Is Member Of Team Or Club is required.");
      hasErrors = true;
    }

    if (!secondaryEmergencyContact) {
      console.log("second contact");

      setSecondaryEmergencyContactError(
        "Secondary Emergency Contact is required."
      );
      hasErrors = true;
    }
    if (!primaryEmergencyContact) {
      console.log("primary contact");

      setPrimaryEmergencyContactError("Primary Emergency Contact is required.");
      hasErrors = true;
    }
    if (!secondaryEmergencyContactEmail) {
      console.log("email second");

      setSecondaryEmergencyContactEmailError("Email is required.");
      hasErrors = true;
    }
    if (!primaryEmergencyContactEmail) {
      console.log("primary email");

      setPrimaryEmergencyContactEmailError("Email is required.");
      hasErrors = true;
    }
    if (!secondaryCallPhoneNumber) {
      console.log("second cell");

      setSecondaryCallPhoneNumberError("Cell Phone Number is required.");
      hasErrors = true;
    }
    if (!primaryCallPhoneNumber) {
      console.log("cell phone prmary");

      setPrimaryCallPhoneNumberError("Cell Phone Number is required.");
      hasErrors = true;
    }
    if (!phoneNumber) {
      console.log("phoneNumber");

      setPhoneNumberError("Phone Number is required.");
      hasErrors = true;
    }
    if (!howDidYouHear) {
      console.log("hor did you");

      setHowDidYouHearError("How Did You Hear is required.");
      hasErrors = true;
    }

    if (!healthConditions) {
      console.log("helth");

      setHealthConditionsError("Health Condition is required.");
      hasErrors = true;
    }

    if (!agreeRelease) {
      console.log("agree");

      setAgreeReleaseError("Agree Release is required.");
      hasErrors = true;
    }

    if (!agreeActive) {
      console.log("active");

      setAgreeActiveError("Agree Active is required.");
      hasErrors = true;
    }

    // if (!signatureType) {
    //   setSignatureTypeError("Signature Type is required.");
    //     hasErrors = true;
    // }

    if (!electronicSignature) {
      console.log("signnature");

      setElectronicSignatureError("Electronic Signature is required.");
      hasErrors = true;
    }

    if (hasErrors) {
      // Display validation errors and prevent form submission
      return;
    }

    const checkRequiredFields = () => {
      const requiredFields = [
        participant,
        cityOfRegistration,
        nameOfSelectedCampEvent,
        dateOfBirth,
        isParentGuardian,
        ext,
        campType,
        // agegroup,
        jerseySize,
        email,
        profile,
        grade,
        // event,
        schoolName,
        isMemberOfTeamOrClub,
        clubName,
        primaryEmergencyContact,
        secondaryEmergencyContact,
        primaryCallPhoneNumber,
        phoneNumber,
        secondaryCallPhoneNumber,
        primaryEmergencyContactEmail,
        secondaryEmergencyContactEmail,
        howDidYouHear,
        healthConditions,
        firstName,
        lastName,
        parentEmailAddress,
        cellPhone,
        businessPhone,
        homePhone,
        country,
        electronicSignature,
        height,
        weight,
        experience,
      ];

      // Check if any required field is empty
      const isEmpty = requiredFields.some((field) => field === "");

      return !isEmpty; // Return true if all required fields are filled
    };

    const allRequiredFilled = checkRequiredFields();

    console.log("Data", allRequiredFilled);
    if (allRequiredFilled) {
      const isCamp = campsEventsList?.filter(
        (camp) => camp.name === nameOfSelectedCampEvent
      );
      const campID = isCamp[0]?.id;
      const data = {
        participantName: participant,
        cityOfRegistration: cityOfRegistration,
        campID,
        type: campType,
        campName: nameOfSelectedCampEvent,
        dateOfBirth,
        isAdult: isParentGuardian,
        message,
        ext,
        // agegroup,
        jerseySize,
        email,
        // campImage: campImageLink,
        profile,
        grade,
        league: nameOfSelectedCampEvent,
        schoolName,
        isMemberOfOtherTeam: isMemberOfTeamOrClub,
        clubName,
        primaryPersonName: primaryEmergencyContact,
        secondaryPersonName: secondaryEmergencyContact,
        primaryCallPhoneNumber,
        phoneNumber,
        secondaryCallPhoneNumber,
        primaryEmergencyContactEmail,
        secondaryEmergencyContactEmail,
        heardAboutLeague: howDidYouHear,
        healthConditions,
        parentFirstName: firstName,
        parentLastName: lastName,
        parentEmailAddress,
        parentPhoneNumber: cellPhone,
        parentBusinessPhoneNumber: businessPhone,
        parentHomePhoneNumber: homePhone,
        parentCountry: country,
        signature: electronicSignature,
        HEIGHT: height,
        WEIGHT: weight,
        experience,
        courseID: [course],
      };
      // console.log("Register As Camp Dat", data, user);
      //  dispatch(registerAsCamp(data,user));
      // localStorage.setItem("formData", JSON.stringify(data));
      // navigate("/session/camp");
      dispatch(registerAsCamp(data, user));

      setParticipantError("");
      setAgeGroupError("");
      setCityOfRegistrationError("");
      // setEventError(""); // Reset error
      setNameOfSelectedCampEventsError("");
      setFirstNameError("");
      setLastNameError("");
      setEmailError("");
      setCellPhoneError("");
      setBusinessPhoneError("");
      setExtError("");
      setHomePhoneError("");
      setProfileError("");
      setCountryError("");
      setCourseError("");
      setCampTypeError("");
      setDateOfBirthError("");
      setIsParentGuardianError("");
      setGradeError("");
      setSchoolNameError("");
      setIsMemberOfLeagueError("");
      setJerseySizeError("");
      setIsMemberOfTeamOrClubError("");
      setClubNameError("");
      setSecondaryEmergencyContactError("");
      setSecondaryEmergencyContactEmailError("");
      setSecondaryCallPhoneNumberError("");
      setPrimaryEmergencyContactError("");
      setPrimaryEmergencyContactEmailError("");
      setPrimaryCallPhoneNumberError("");
      setPhoneNumberError("");
      setHowDidYouHearError("");
      setHealthConditionsError("");
      setAgreeReleaseError("");
      setAgreeActiveError("");
      setSignatureTypeError("");
      setElectronicSignatureError("");
      setHeightError("");
      setWeightError("");
      setExperienceError("");
    }
  };
  const stateSetters = {
    message,
    setMessage,
    showMessage,
    setShowMessage,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    setEmail,
    email,
    setCellPhone,
    cellPhone,
    setBusinessPhone,
    businessPhone,
    setExt,
    ext,
    setHomePhone,
    homePhone,
    setCountry,
    country,
    setDateOfBirth,
    dateOfBirth,
    setIsParentGuardian,
    isParentGuardian,
    setGrade,
    grade,
    setSchoolName,
    schoolName,
    setIsMemberOfLeague,
    isMemberOfLeague,
    setJerseySize,
    jerseySize,
    setIsMemberOfTeamOrClub,
    isMemberOfTeamOrClub,
    setClubName,
    clubName,
    setParentEmailAddress,
    parentEmailAddress,
    setSecondaryEmergencyContact,
    secondaryEmergencyContact,
    setPrimaryEmergencyContact,
    primaryEmergencyContact,
    setPrimaryEmergencyContactEmail,
    primaryEmergencyContactEmail,
    setPrimaryCallPhoneNumber,
    primaryCallPhoneNumber,
    setPhoneNumber,
    phoneNumber,
    setSecondaryEmergencyContactEmail,
    secondaryEmergencyContactEmail,
    setSecondaryCallPhoneNumber,
    secondaryCallPhoneNumber,
    setHowDidYouHear,
    howDidYouHear,
    setHealthConditions,
    healthConditions,
    setAgreeRelease,
    agreeRelease,
    setAgreeActive,
    agreeActive,
    setSignatureType,
    signatureType,
    setElectronicSignature,
    electronicSignature,
    setFirstNameError,
    firstNameError,
    setLastNameError,
    lastNameError,
    setEmailError,
    emailError,
    setCellPhoneError,
    cellPhoneError,
    setBusinessPhoneError,
    businessPhoneError,
    setExtError,
    extError,
    setHomePhoneError,
    homePhoneError,
    setCountryError,
    countryError,
    setDateOfBirthError,
    dateOfBirthError,
    setIsParentGuardianError,
    isParentGuardianError,
    setGradeError,
    gradeError,
    setSchoolNameError,
    schoolNameError,
    setIsMemberOfLeagueError,
    isMemberOfLeagueError,
    setJerseySizeError,
    jerseySizeError,
    setIsMemberOfTeamOrClubError,
    isMemberOfTeamOrClubError,
    setClubNameError,
    clubNameError,
    setSecondaryEmergencyContactError,
    secondaryEmergencyContactError,
    setPrimaryEmergencyContactError,
    primaryEmergencyContactError,
    setPrimaryEmergencyContactEmailError,
    primaryEmergencyContactEmailError,
    setPrimaryCallPhoneNumberError,
    primaryCallPhoneNumberError,
    setPhoneNumberError,
    phoneNumberError,
    setSecondaryEmergencyContactEmailError,
    secondaryEmergencyContactEmailError,
    setSecondaryCallPhoneNumberError,
    secondaryCallPhoneNumberError,
    setHowDidYouHearError,
    howDidYouHearError,
    setHealthConditionsError,
    healthConditionsError,
    setAgreeReleaseError,
    agreeReleaseError,
    setAgreeActiveError,
    agreeActiveError,
    setSignatureTypeError,
    signatureTypeError,
    setElectronicSignatureError,
    electronicSignatureError,
    parentEmailAddressError,
    setParentEmailAddressError,
  };

  useEffect(() => {
    if (userError) {
      dispatch(clearUserErrors());
    }

    if (userError) {
      dispatch(clearUserErrors());
    }
  }, [created]);

  if (
    userLoading ||
    campLoading ||
    loading ||
    courseLoading ||
    fetchingCampsEvents
  ) {
    return <Loader />;
  }
  return (
    <div className="">
      <ToastContainer />
      <div className="md:pr-2 mt-12 sm:pr-2 md:ml-1 md:pl-2 sm:ml-2 sm:pl-2 md:flex ">
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold">SIGNUP FOR CAMP</div>
          <div className="mt-4">
            <h5>Participant</h5>
            <input
              name="participant"
              type="text"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={participant}
              onChange={handleParticipantChange}
            />
            <span className="text-red-500">{participantError}</span>
          </div>
          <div className="mt-4">
            <h5 className="">Camps</h5>
            <select
              name="Camps"
              id="age"
              value={campType}
              onChange={handleCampsChange}
              className="  border-[#e7e7e7] uppercase border-2 rounded-md p-2 w-full "
            >
              <option value="" className="uppercase">
                Choose a Camps
              </option>
              {camps?.map((item, index) => {
                return (
                  <option value={item?.title} className="uppercase" key={index}>
                    {item?.title}
                  </option>
                );
              })}
            </select>
          </div>
          <span className="text-red-500 pb-2">{campTypeError}</span>

          <div className="mt-4">
            <h5 className="">Course</h5>
            <select
              name="CampCourse"
              id="program"
              value={course}
              onChange={handleCourseChange}
              className="  border-[#e7e7e7] uppercase border-2 rounded-md p-2 w-full "
            >
              <option value="" className="uppercase">
                Select Course
              </option>
              {campCourses?.map((item, index) => {
                return (
                  <option
                    value={item?.id}
                    className="uppercase"
                    key={index?.id}
                  >
                    {item?.title} - ${item?.price} /hr
                  </option>
                );
              })}
            </select>
          </div>
          <span className="text-red-500 pb-2">{courseError}</span>

          <div className="mt-2 scheduleMeet">
            {course && <Calendar appointmentId={course} />}
          </div>
          <div className="mt-4">
            <h5>Date of birth</h5>
            <input
              type="date"
              value={dateOfBirth}
              className="border border-2"
              onChange={handleDateOfBirthChange}
            />
          </div>
          <span className="text-red-500 pb-2">{dateOfBirthError}</span>
          <div className="mt-4">
            <h5>City of Registration</h5>
            <select
              name="city_of_registration"
              id="city_of_registration"
              value={cityOfRegistration}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleCityOfRegistrationChange}
            >
              <option value="" className="uppercase">
                Choose option
              </option>

              {citylist?.map((value, index) => (
                <option value={value.name} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <span className="text-red-500">{cityOfRegistrationError}</span>
          </div>
          {/* <div className="mt-4">
            <h5>Event Signing up for</h5>
            <select
              name="event"
              id="event"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={event}
              onChange={handleEventChange}
            >
              <option value="default" defaultValue="Choose Options">
                Choose option
              </option>

              {leagues?.map((value, index) => (
                <option value={value?.title} key={index}>
                  {value?.title}
                </option>
              ))}
            </select>
            <span className="text-red-500">{eventError}</span>
          </div> */}
          <div className="mt-4">
            <h5>Camp Signing Up For</h5>
            <select
              name="name_of_registered_camp"
              id="name_of_registered_camp"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={nameOfSelectedCampEvent}
              onChange={handleNameOfSelectedCampsEvents}
            >
              <option value="" className="uppercase">
                Choose Camps
              </option>
              {filterCampsList?.map((value, index) => (
                <option value={value.name} key={index}>
                  {value.name}
                </option>
              ))}
              {/* <option value="Other Teams">Other Teams</option> */}
            </select>
            {/* {nameOfSelectedCampEvent === "Other Teams" && (
              <div className="flex flex-col gap-1">
                <input
                  name="camp"
                  type="text"
                  className="border-[#e7e7e7] mt-2 border-2 rounded-md p-2 w-full"
                  value={newTeam}
                  placeholder="Team Name"
                  onChange={createNewTeam}
                />
                {errorMessage && (
                  <p className="text-red-500 py-2">errorMessage</p>
                )}
                <label
                  htmlFor="campImageInput"
                  className="cursor-pointer bg-gray-800 max-w-fit hover:bg-gray-600 text-white py-2 px-4 rounded-lg"
                >
                  Upload Team Image
                </label>
                <input
                  type="file"
                  id="campImageInput"
                  className="hidden"
                  onChange={handleTeamImage}
                />
                {newTeamImage && (
                  <p className="mt-2">Selected File: {newTeamImage.name}</p>
                )}
              </div>
            )} */}
            <span className="text-red-500">{nameOfSelectedCampEventError}</span>
          </div>
          <div className="mt-4 mb-2">
            <h5 className="mb-2">Upload Profile Picture</h5>
            <label
              htmlFor="profileInput"
              className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
            >
              Upload Profile Picture
            </label>
            <input
              type="file"
              id="profileInput"
              className="hidden"
              onChange={handleProfile}
            />
            {profile && <p className="mt-2">Selected File: {profile.name}</p>}
          </div>
          <span className="text-red-500 pb-2">{profileError}</span>

          <div className="mt-4">
            <h5>Height</h5>
            <input
              name="height"
              type="text"
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={height}
              onChange={handleHeightChange}
            />
            <span className="text-red-500 pb-2">{heightError}</span>
          </div>
          <div className="mt-4">
            <h5>Weight</h5>
            <input
              name="weight"
              type="text"
              className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
              value={weight}
              onChange={handleWeightChange}
            />
            <span className="text-red-500 pb-2">{weightError}</span>
          </div>
          <div className="mt-4">
            <h5>Experience</h5>
            <select
              name="experience"
              id="experience"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={experience}
              onChange={handleExperienceChange}
            >
              <option value="" defaultValue="">
                Choose option
              </option>
              {experiences?.map((data, index) => (
                <option value={data.value}>{data.value}</option>
              ))}
            </select>
            <span className="text-red-500">{experienceError}</span>
          </div>
          <SignUpDetail stateSetters={stateSetters} />
          <div className="flex pt-20 mb-5 ml-2 mr-2">
            <button
              onClick={handleRegister}
              className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[9rem]  md:flex-row flex-col ">
              <NavLink to="/signuptournament">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm ">Tournament</div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-4">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">CAMPS</div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-4">
                <img
                  src="/images/Ticket.svg"
                  alt="Ticket"
                  className="inline h-12 mb-2 w-16"
                />
                <div className="font-bold text-sm mb-4">EVENTS</div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Volunteer</div>
              </NavLink>
              <NavLink to="/signupworkwithus" className="mb-2">
                <RiSuitcaseLine className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Work with us</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#181B22] text-white">
        <div className="md:p-20">
          <AdvertisementOne />
        </div>
        <Teams />
        <div className="md:p-20 b-[#181B22]">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 b-[#181B22] flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link
              to="/store"
              className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
            >
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpCamp;
