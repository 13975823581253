import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";

const useTryout = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [alreadyPlayer, setAlreadyPlayer] = useState(null);
  const navigate = useNavigate();

  // Function to check if the user is already registered
  const fetchAlreadyRegistered = async (id) => {
    try {
      setLoading(true);

      console.log("Checking registration status for user:", user);

      const response = await API.graphql({
        query: queries.listTryouts,
        variables: {
          filter: {
            userId: {
              eq: id,
            },
          },
        },
      });

      const tryoutData = response?.data?.listTryouts?.items;
      console.log("Tryout data:", tryoutData);

      if (tryoutData && tryoutData.length > 0) {
        setAlreadyPlayer(true);
      //  window.location.href = "/";
        return true;
      } else {
        setAlreadyPlayer(false);
      }
    } catch (err) {
      console.error("Error fetching registration:", err);
      throw new Error(err.message);
    } finally {
      setLoading(false);
    }
    return false;
  };

  // Function to register for a tryout
  const registerTryoutFunc = async (data) => {
    try {
      setLoading(true);

      const alreadyRegistered = await fetchAlreadyRegistered(user);

      if (isAuthenticated) {
        if (alreadyPlayer || alreadyRegistered) {
          toast.error("Already Registered");
          window.location.href = "/";
          return;
        }

        const checkoutPayload = {
          body: {
            // success_url: "https://www.sfbayareabasketball.com/payment-success",
            // success_url: `http://localhost:3000/payment-success?id=${user}&&isTryout=true`,
            success_url: `https://www.sfbayareabasketball.com/payment-success?id=${user}&&isTryout=true`,
            cancel_url: "https://www.sfbayareabasketball.com/payment-failure",
            ...data,
            userId: user,
          },
        };

        const response = await API.post(
          "signupTryoutApi",
          "/signuptryout",
          checkoutPayload
        );

        if (response?.url) {
          window.location.href = response.url;
        }
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message;
      setError(errorMessage);
      setSuccess(false);
      toast.error(errorMessage);
      console.error("Error during registration:", err);
    } finally {
      setLoading(false);
    }
  };

  const verifyPayment = async () => {
    try {
      const checkoutPayload = {
        body: {
          // success_url: "http://localhost:3000/order-success",
          // cancel_url: "http://localhost:3000/cart",
          userId: user,
        },
      };
      console.log("checkoutPayload", checkoutPayload);

      // // Send a request to the Stripe API to create a checkout session
      const response = await API.get(
        "signupTryoutApi",
        `/signuptryout/verifypayment?userId=${user}`
      );
    } catch (error) {
      console.log("Error Verifying Payment", error);
      throw new Error("Error Verifying Payment");
    }
  };

  // Fetch registration status when the user changes
  useEffect(() => {
    if (user) {
      fetchAlreadyRegistered(user);
    }
  }, [user]);

  return { registerTryoutFunc,verifyPayment,fetchAlreadyRegistered,alreadyPlayer, error, loading, success };
};

export default useTryout;
