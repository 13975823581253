import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import Shop from "../../Components/Shop";
import { NavLink, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import RenderShop from "../../Components/Shop/RenderShop";
import useHandlePagintationPlayers from "../../hooks/handlePaginationPlayer";
import useTryout from "../../hooks/useTryout";
import useTryout5X5 from "../../hooks/useTryout5X5";
import { useHandleComptetivePlayers } from "../../hooks/handleComptetivePlayers";
import { useHandleComptetive5x5Players } from "../../hooks/handleComptetive5x5Players";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PaymentSuccess = () => {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  const query = useQuery();
  const isComptetive = query.get("isComptetive"); // Replace "token" with the name of your query parameter
  const isComptetive5x5 = query.get("isComptetive5x5"); // Replace "token" with the name of your query parameter
  const isTryout = query.get("isTryout"); // Replace "token" with the name of your query parameter
  const isTryout5X5 = query.get("isTryout5X5"); // Replace "token" with the name of your query parameter
  const id = query.get("id"); // Replace "token" with the name of your query parameter

  const { verifyPayment } = useHandlePagintationPlayers();
  const { verifyPayment: verifyTryoutPayment } = useTryout();
  const { verifyPayment: verifyTryout5X5Payment } = useTryout5X5();
  const { verifyPaymentOfComptetivePlayer } = useHandleComptetivePlayers();
  const { verifyPaymentOfComptetive5x5Player } = useHandleComptetive5x5Players();
  const verify = async () => {

    if (isComptetive === 'true' && id || isComptetive === true && id) {
      console.log("isComptetive", isComptetive, id)
      await verifyPaymentOfComptetivePlayer(id)
    }else if (isComptetive5x5 === 'true' && id || isComptetive5x5 === true && id) {
      console.log("isComptetive5x5", isComptetive5x5, id)
      await verifyPaymentOfComptetive5x5Player(id)
    }else if (isTryout === 'true' && id || isTryout === true && id) {
      console.log("isTryout", isTryout, id);
      await verifyTryoutPayment(id);
    }else if (isTryout5X5 === 'true' && id || isTryout5X5 === true && id) {
      console.log("isTryout5X5", isTryout5X5, id);
      await verifyTryout5X5Payment(id);
    }  else {
      console.log("isNoTComptetive", isComptetive, id)

      await verifyPayment()
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate("/"); // Use navigate instead of history.push
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown, navigate, id, isComptetive, isTryout]);

  useEffect(() => {
    if(id){

      verify();
    }

  }, [id]);


  return (
    <>
      <div className="flex items-center justify-center px-4 h-screen">
        <div className="flex flex-col items-center">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnD9hBDflAxljspG1wpOwn0LAvKlT6xgpjUA&usqp=CAU"
            className="h-52 md:w-80 w-full lg:w-80 object-contain"
            alt="Payment Failure"
          />
          <h1 className="uppercase font-bold underline text-green-500">
            Success
          </h1>
          <p className="uppercase font-bold sm:text-left">
            Payment was Successfull!
          </p>
          <p>
            Redirecting to the home page in {countdown} sec or else click button
            below
          </p>
          <NavLink
            to="/"
            className="bg-[#fec749] mt-2 mb-2 px-4 py-2 rounded-md"
          >
            Done
          </NavLink>
        </div>
      </div>

      <div className="p-20 bg-[#181B22] text-white">
        <div className="py-2 uppercase font-bold text-4xl border-b-2 border-[#c4c4c4]">
          <span className="border-l-4 border-[#D70D15] text-3xl px-2">
            shop
          </span>

          <div className="float-right text-3xl">
            <button className="text-[#D70D15] font-bold mr-8">
              <AiOutlineLeft className="text-lg font-bold" />
            </button>
            <button className="text-[#D70D15] font-bold">
              <AiOutlineRight className="text-lg font-bold" />
            </button>
          </div>
        </div>
        <RenderShop />
        <div className="text-center">
          <button className="border-2 p-2 border-white rounded-md w-60 mr-20">
            VIEW ALL
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
